import React, { FormEvent }  from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { hideModal, showModal, loginUser, onLoginPage } from '../../actions'
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { Container, Col, Row } from 'react-bootstrap';

type UpdateEmailParam = React.SyntheticEvent<{ value: string }>;

interface PathParamsType {

}

type Props = RouteComponentProps<PathParamsType> & {
    hideModal: typeof hideModal
    showModal: typeof showModal
    loginUser: typeof loginUser
    onLoginPage: typeof onLoginPage
    bearerToken: string | null,
    loginError: Error | null
}

class LoginPage extends React.Component<Props> {
    
    state = {
        email: '',
        password: '',
        rememberMe: false,
    };

    constructor(props: any){
        super(props)
        this.handleLogin = this.handleLogin.bind(this)
        this.updatePassword = this.updatePassword.bind(this)
        this.updateEmail = this.updateEmail.bind(this)
        this.updateRememberMe = this.updateRememberMe.bind(this)
        this.handleRegisterationClick = this.handleRegisterationClick.bind(this)
    }

    componentDidMount(){
        this.props.onLoginPage()
        if(this.props.bearerToken){
            this.props.history.goBack()   
        }
    }

    componentDidUpdate(oldProps: Props){
        if(this.props.bearerToken){
            this.props.history.goBack()   
        }
    }
    
    updateEmail(event: UpdateEmailParam) {
        this.setState({ email: event.currentTarget.value });
    };

    updatePassword(event: UpdateEmailParam) {
        this.setState({ password: event.currentTarget.value });
    };

    updateRememberMe(event: React.ChangeEvent<HTMLInputElement>){
        this.setState({ rememberMe: !this.state.rememberMe})
    }

    handleLogin(e: FormEvent){
        e.preventDefault()
        this.props.loginUser(this.state.email, this.state.password, this.state.rememberMe)
    }

    handleRegisterationClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>){
        e.preventDefault()
        this.props.showModal("REGISTRATION_MODAL")
    }

    render() {
        return (
            <>
            { !this.props.bearerToken && <Container>
                <Row className="justify-content-md-center">
                    <Col md='auto'>
                        <Link to='/'><h3>The Nickname Graph</h3></Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Modal.Dialog>
                            <Modal.Header>
                                <Modal.Title>
                                    Login
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form onSubmit={this.handleLogin}>
                                    <Form.Group>
                                        <Form.Control onChange={this.updateEmail} type="email" placeholder="Enter Email" required/>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Control onChange={this.updatePassword} type="password" placeholder="Enter Password" required/>
                                    </Form.Group>
                                    {this.props.loginError && (
                                        <Alert dismissible variant="danger">
                                            <Alert.Heading>Error</Alert.Heading>
                                            <p>
                                                there was an error logging in.
                                            </p>
                                        </Alert>
                                    )}
                                    <Button variant="primary" type="submit">Login</Button>
                                    <Form.Group>
                                        <Form.Check onChange={this.updateRememberMe} type="checkbox" label="remember me" />
                                    </Form.Group>
                                </Form>
                                <p>Not a member? <Link to ="./" onClick={this.handleRegisterationClick} >Join the community</Link></p>
                            </Modal.Body>
                        </Modal.Dialog>
                    </Col>
                </Row>
            </Container>}
            </>
        );
      }
}

const mapStateToProps = (state: AppState) => ({
    isVisible: state.uiStates.modalVisibleStates.LOGIN_MODAL,
    loginError: state.user.error,
    bearerToken: state.user.bearerToken
});

export default withRouter(connect(
    mapStateToProps,
    { hideModal, showModal, loginUser, onLoginPage }
)(LoginPage))
