import * as React from 'react'
import './index.css'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import Iframe from 'react-iframe'

interface Props {
    height: number
}

interface State {

}

class APIPage extends React.Component<Props> {
    render(){
        return (
            <Iframe 
                url="https://api.nicknamegraph.com/graphql/"
                width="100%"
                height={`${this.props.height}px`}
                className="graphiql-frame"
                position="relative"
            />
        )
    }
}

const mapStateToProps = (state: AppState) => ({
    height: state.uiStates.canvas.height
});

export default connect(
	mapStateToProps,
	{ }
)(APIPage);