import './index.css'
import React, { } from 'react'
import DrawableComponent from '../DrawableComponent'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import { NameMemo } from '../../actions'

interface Props {
	uuid: string
	width: number
	height: number
	nameMemo: NameMemo
	ref?: string
}

class NodeStack extends DrawableComponent<Props> {

	drawElement(ctx: CanvasRenderingContext2D){
		let element = this.props.nameMemo[this.props.uuid]
		// let colors = {
		// 	nickname:'#4d94ff',
		// 	primaryName: '#e6c300',
		// 	formalName: '#00cc00'
		// }

		const focalLength = 1000
		const layers = 10

		for(let i = layers; i > 0; i--){
			const depthStep = 50
			ctx.beginPath();
			let left = focalLength * element.current.left / (focalLength + i * depthStep)
			let right = focalLength * element.current.top / (focalLength + i * depthStep)
			let radius = focalLength * element.radius / (focalLength + i * depthStep)
			
			ctx.arc(left + this.props.width/2, right + this.props.height/2 , radius , 0, 2 * Math.PI);
			ctx.lineWidth = 3;
			ctx.strokeStyle = `rgb(255, 217, 0, ${1 - i/layers})`
			ctx.stroke();

		}
		
		ctx.lineWidth = 0;
		ctx.strokeStyle = "black"
	}

	render(){
		return(
			<></>
		)
	}

}

const mapStateToProps = (state: AppState) => ({
	nameMemo: state.names,
	width: state.uiStates.canvas.width,
	height: state.uiStates.canvas.height,
});

export default connect(
	mapStateToProps,
	{ },
	null,
	{ forwardRef: true },
)(NodeStack)
