import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import { showModal, logout } from '../../actions'
import { LinkContainer } from 'react-router-bootstrap'
import NameSearchBar from '../NameSearch'
import logo from './NNGLogo.png' 

interface Props {
    showModal: typeof showModal,
    bearerToken: string | null,
    logout: typeof logout,
}

class  NNGNavbar extends React.Component<Props> {
    state = {
        toLogin: false,
    }



    render(){
        return(
            <>
                <Navbar bg="light" expand="lg">
                    <LinkContainer to="/"><Navbar.Brand><img src={logo} alt="NNGlogo" height="32" width="32" />Nickname Graph</Navbar.Brand></LinkContainer>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                        <Nav className="mr-auto">
                            <LinkContainer to="/Browse"><Nav.Link>Browse</Nav.Link></LinkContainer>
                            <LinkContainer to="/Store"><Nav.Link>Store</Nav.Link></LinkContainer>
                            <LinkContainer to="/API"><Nav.Link>API</Nav.Link></LinkContainer>
                            <LinkContainer to="#" onClick={() => { this.props.showModal("ABOUT_MODAL") }}><Nav.Link>About</Nav.Link></LinkContainer>
                            <NameSearchBar />
                        </Nav>
                            {!this.props.bearerToken && (
                                <Button 
                                    variant="outline-success" 
                                    onClick={() => { this.props.showModal("LOGIN_MODAL") }}
                                >
                                    Login to the community
                                </Button>
                            )}
                            {this.props.bearerToken && (
                                <Button 
                                    variant="outline-warning" 
                                    onClick={() => {this.props.logout()}}
                                >
                                    logout
                                </Button>
                            )} 
                    </Navbar.Collapse>
                </Navbar>
            </>
        )
    }
} 

const mapStateToProps = (state: AppState) => ({
    bearerToken: state.user.bearerToken,
    searchQuery: state.nameSearch.query
});

export default connect(
    mapStateToProps,
    { showModal, logout }
)(NNGNavbar);