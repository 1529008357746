import './App.css';
import React, { Component } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import { AppState } from './reducers'
import { connect } from 'react-redux';
import NNGNavbar from './component/NNGNavbar'
import NameGraph from './component/NameGraph'
import ErrorPage from './component/Error'
import LoginPage from './component/Login'
import RegisterModal from './component/Modals/RegisterModal'
import AboutModal from './component/Modals/AboutModal'
import APIPage from './component/API'
import { loginFromStorage, setCanvasSize } from './actions'

interface Props{
	loginFromStorage: typeof loginFromStorage
	goToLoginPage: boolean
	setCanvasSize: typeof setCanvasSize
}

interface State {
	width: number
	height: number
}
class App extends Component<Props> {
	
	state: State = { 
		width: 0, 
		height: 0 
	};

	constructor(props: Props){
		super(props)
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentWillMount() {
		this.props.loginFromStorage()
	}

	componentDidMount(){
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
		
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight - 56 });
		this.props.setCanvasSize(window.innerWidth, window.innerHeight - 56)
	}

	render() {
		return (
			<div className="wrap">
				<BrowserRouter>
					<Switch>
						<Route path={'/login'} exact component={LoginPage} />
						<Route path={'/'} component={ () => {
							return (<>
								<NNGNavbar />
								<div id='main-container'>
									<div className="mainWidth justify-content-md-center">
										<Switch>
											<Route path={'/'} exact component={NameGraph} />
											<Route path={'/name/:name'} exact component={NameGraph} />
											<Route path={'/API'} exact component={APIPage} />
											<Route component={ErrorPage} />
										</Switch>
									</div>
								</div>
							</>)
						}} />
					</Switch>
					{
						this.props.goToLoginPage && <Redirect push exact to={'/login'}/>
					}
					<RegisterModal />
					<AboutModal />
				</BrowserRouter>
			</div>	
		)
	}
}

const mapStateToProps = (state: AppState) => ({
	goToLoginPage: state.uiStates.goToLogin
	//loggedIn: state.login.logged_in
});

export default connect(
	mapStateToProps,
	{ loginFromStorage, setCanvasSize }
)(App);
