import axios, { AxiosResponse } from 'axios'
import * as NameTypes from './name.types'
import { hideModal, showModal } from './ui.actions'
import { Dispatch } from 'redux'
import { store } from '../index'
import { GraphQLResponse, GetNameResponse } from './graphQL.response.types'

const getNameAttempt = (currentName: string): NameTypes.GetNameAttempt => {
	return {
		type: NameTypes.GET_NAME_ATTEMPT,
		currentName: currentName
	}
}
const getNameSuccess = (nameNode: NameTypes.Name, nameMemo: NameTypes.NameMemo, edgeMemo: NameTypes.EdgeMemo): NameTypes.GetNameSuccess => {
	return {
		type: NameTypes.GET_NAME_SUCCESS,
		name: nameNode,
		nameMemo: nameMemo,
		edgeMemo: edgeMemo,
	}
}
const getNameFail = (err: Error): NameTypes.GetNameFail => {
	return {
		type: NameTypes.GET_NAME_FAIL,
		err: err
	}
}

const suggestNameAttempt = (): NameTypes.SuggestNameAttempt => {
	return {
		type: NameTypes.SUGGEST_NAME_ATTEMPT
	}
}

const suggestNameSuccess = (): NameTypes.SuggestNameSuccess => {
	return {
		type: NameTypes.SUGGEST_NAME_SUCCESS
	}
}

const suggestNameFail = (err: Error): NameTypes.SuggestNameFail => {
	return {
		type: NameTypes.SUGGEST_NAME_FAIL,
		err: err
	}
}

const likeNameAttempt = (): NameTypes.LikeNameAttempt => {
	return {
		type: NameTypes.LIKE_NAME_ATTEMPT
	}
}

const likeNameSuccess = (name: NameTypes.Name): NameTypes.LikeNameSuccess => {
	return {
		type: NameTypes.LIKE_NAME_SUCCESS,
		name: name
	}
}

const likeNameFail = (err: Error): NameTypes.LikeNameFail => {
	return {
		type: NameTypes.LIKE_NAME_FAIL,
		err: err
	}
}

const likeNamePairAttempt = (): NameTypes.LikeNamePairAttempt => {
	return {
		type: NameTypes.LIKE_NAME_PAIR_ATTEMPT
	}
}

const likeNamePairSuccess = (uuidToUpdate: string): NameTypes.LikeNamePairSuccess => {
	return {
		type: NameTypes.LIKE_NAME_PAIR_SUCCESS,
		uuidToUpdate: uuidToUpdate
	}
}

const likeNamePairFail = (err: Error): NameTypes.LikeNamePairFail => {
	return {
		type: NameTypes.LIKE_NAME_PAIR_FAIL,
		err: err
	}
}

const flagNameAttempt = (): NameTypes.FlagNameAttempt => {
	return {
		type: NameTypes.FLAG_NAME_ATTEMPT
	}
}

const flagNameSuccess = (name: NameTypes.Name): NameTypes.FlagNameSuccess => {
	return {
		type: NameTypes.FLAG_NAME_SUCCESS,
		name: name
	}
}

const flagNameFail = (err: Error): NameTypes.FlagNameFail => {
	return {
		type: NameTypes.FLAG_NAME_FAIL,
		err: err
	}
}

const flagNamePairAttempt = (): NameTypes.FlagNamePairAttempt => {
	return {
		type: NameTypes.FLAG_NAME_PAIR_ATTEMPT
	}
}

const flagNamePairSuccess = (uuidToUpdate: string): NameTypes.FlagNamePairSuccess => {
	return {
		type: NameTypes.FLAG_NAME_PAIR_SUCCESS,
		uuidToUpdate: uuidToUpdate
	}
}

const flagNamePairFail = (err: Error): NameTypes.FlagNamePairFail => {
	return {
		type: NameTypes.FLAG_NAME_PAIR_FAIL,
		err: err
	}
}

export const updateSuggestNamePair = (namePair: NameTypes.NamePair, relationToSuggest: "nickname" | "formalName", explanation: string | undefined ): NameTypes.UpdateSuggestNamePair => {
	return {
		type: NameTypes.UPDATE_SUGGEST_NAME_PAIR,
		namePair: namePair,
		relationToSuggest: relationToSuggest,
		explanation: explanation
	}
}

const cursorToId = (cursor:string): string => {
	//this takes a cursor from the NNDB and turns it into a unique id.
	//Don't do this, this is undocumented and liable to change without notice.
	//sorry.
	return `${window.atob(cursor).split(',')[1]}`
}

const rotatePoint = (x:number, y:number, rads:number):{x:number, y:number} => {
	return {
		x: Math.cos(rads)*x - Math.sin(rads)*y,
		y: Math.sin(rads)*x + Math.cos(rads)*y
	}
}

const updateNameMemo = (nameNode: NameTypes.Name, nameMemo: NameTypes.NameMemo, edgeMemo: NameTypes.EdgeMemo): [NameTypes.NameMemo, NameTypes.EdgeMemo] => {
	
	let edgeIds:NameTypes.EdgeIds = []

	let edges: NameTypes.EdgeMemo = {}

	if(nameNode.nicknames && nameNode.nicknames.edges){
		nameNode.nicknames.edges.forEach((edge: NameTypes.Edge) => {
			edges[cursorToId(edge.cursor)] = {
				start: {
					uuid: nameNode.uuid
				},
				end: {
					uuid: edge.node.uuid
				}
			}
		})
	}
	
	if(nameNode.formalNames && nameNode.formalNames.edges){
		nameNode.formalNames.edges.forEach((edge: NameTypes.Edge) => {
			edges[cursorToId(edge.cursor)] = {
				start: {
					uuid: edge.node.uuid
				},
				end: {
					uuid: nameNode.uuid
				}
			} 
		})
	}

	let newEdgeMemo = {
		...edgeMemo,
		...edges
	}

	let newNameMemo = {
		...nameMemo
	}

	// const spread = 1000

	const startingPoint = rotatePoint(0, 300, Math.random() * Math.PI *2)/** {
		x: Math.floor(Math.random()*spread - spread/2),
		y: Math.floor(Math.random()*spread - spread/2)
	}//rotatePoint(0, 600, Math.random() * Math.PI *2) */
	

	if(!(nameNode.uuid in nameMemo) && nameNode.name_normalized) {
		newNameMemo[nameNode.uuid] = {
			current:{
				top: startingPoint.y,
				left: startingPoint.x,
			},
			target:{
				top: startingPoint.y,
				left: startingPoint.x,
			},
			isLiked: nameNode.isLiked,
			isFlagged: nameNode.isFlagged,
			edgeIsLiked: false,
   		 	edgeIsFlagged: false,
			nameString: nameNode.name_normalized ? nameNode.name_normalized : null,
			uuid: nameNode.uuid,
			numberOfConnections: 0,
			radius: 60,
			relation : "unrelated",
			edgeIds: edgeIds
		}
	}

	if(nameNode.nicknames && nameNode.nicknames.edges){
		nameNode.nicknames.edges.forEach((edge: NameTypes.Edge) => {
			[newNameMemo, newEdgeMemo] = updateNameMemo(edge.node, newNameMemo, newEdgeMemo)
		})
	}
	
	if(nameNode.formalNames && nameNode.formalNames.edges){
		nameNode.formalNames.edges.forEach((edge: NameTypes.Edge) => {
			[newNameMemo, newEdgeMemo] = updateNameMemo(edge.node, newNameMemo, newEdgeMemo)
		})
	}

	return [newNameMemo, newEdgeMemo]
}

const getNodesToSet = (nameNode: NameTypes.Name, nameMemo: NameTypes.NameMemo): [NameTypes.uuid[], NameTypes.NameMemo] => {
	let edges:Array<string> = [nameNode.uuid]
	let newNameMemo:NameTypes.NameMemo = {}

	for(let id in nameMemo){
		if(nameMemo.hasOwnProperty(id)){
			newNameMemo[id] = {
				...nameMemo[id],
				relation: "unrelated"
			}
		}
	}

	newNameMemo[nameNode.uuid].relation = "primaryName"
	
	if(nameNode.nicknames && nameNode.nicknames.edges){
		nameNode.nicknames.edges.forEach((edge: NameTypes.Edge) => {
			edges.push(edge.node.uuid)
			newNameMemo[edge.node.uuid].relation = "nickname"
			newNameMemo[edge.node.uuid].edgeIsFlagged = edge.isFlagged
			newNameMemo[edge.node.uuid].edgeIsLiked = edge.isLiked
		})
	}
	
	if(nameNode.formalNames && nameNode.formalNames.edges){
		nameNode.formalNames.edges.forEach((edge: NameTypes.Edge) => {
			edges.push(edge.node.uuid)
			newNameMemo[edge.node.uuid].relation = "formalName"
			newNameMemo[edge.node.uuid].edgeIsFlagged = edge.isFlagged
			newNameMemo[edge.node.uuid].edgeIsLiked = edge.isLiked
		})
	}

	return [edges, newNameMemo]
}

const renderBuffer = 100

export const getNodesToDisplay = (nameMemo: NameTypes.NameMemo, edgeMemo: NameTypes.EdgeMemo): [NameTypes.uuid[], string[]] => {
	let nodeIds: NameTypes.uuid[] = []
	let edgeIds: string[] = []

	for(let id in nameMemo){
		if(nameMemo.hasOwnProperty(id)){
			nodeIds.push(nameMemo[id].uuid)
		}
	}

	for(let id in edgeMemo){
		if(edgeMemo.hasOwnProperty(id)){
			edgeIds.push(id)
		}
	}

	return [nodeIds, edgeIds]
}

const getLength = (x1: number, y1: number, x2: number, y2: number): number => {
	return Math.sqrt(Math.pow(y1 - y2,2) + Math.pow(x1 - x2, 2))
}

const setNodePositions = (orderedIds: string[], nameMemo: NameTypes.NameMemo, edgeNodes: NameTypes.EdgeMemo, screenWidth: number, screenHeight: number): NameTypes.NameMemo => {
	// const initialOffset = Math.random() * Math.PI *2
	let [visibleNodes] = getNodesToDisplay(nameMemo, edgeNodes)
	// const point = rotatePoint(0, getLength(0, 0, screenWidth/2, screenHeight/2) * 1.5, Math.random() * 7)
	console.log(screenWidth, screenHeight, getLength(0, 0, screenWidth/2 + renderBuffer, screenHeight/2 + renderBuffer), rotatePoint(0, getLength(0, screenWidth/2, 0, screenHeight/2) , 0) )
	visibleNodes.forEach((id, i) => {
		if(id in nameMemo){
			// nameMemo[id].target = { 
			// 	top: point.y,
			// 	left: point.x,
			// }
		}
	})

	orderedIds.forEach((id, i) => {
		if(id in nameMemo){
			if(i === 0){
				//nameMemo[id].target = { top: 0, left:0 }
			} else {
				// const startingPoint = rotatePoint(0, 300 + Math.random() * 50, initialOffset + (Math.PI*2/((orderedIds.length - 1) > 2 ? orderedIds.length - 1 : 3 ) * i) ) 
				//nameMemo[id].target = { top: Math.floor(startingPoint.y), left: Math.floor(startingPoint.x) }
			}
		}
	})

	return nameMemo
}

export type GetName = typeof getName

export const getName = (name: string): any => (dispatch: Dispatch) =>{
	const query = {
		query: `{
			getName(name:"${name}"){
			name_normalized
			uuid
			isLiked
			isFlagged
			formalNames{
				totalCount
			  	edges{
					cursor
					isLiked
					isFlagged
					node{
						name_normalized
						uuid
						isLiked
						isFlagged
						formalNames{
							totalCount
							edges{
								cursor
								node{
									uuid
									name_normalized
									isLiked
									isFlagged
								}
							}
						}
						nicknames{
							totalCount
							edges{
								cursor
								node{
									uuid
									name_normalized
									isLiked
									isFlagged
								}
							}
						}
					}
				}  
			}
			nicknames{
				totalCount
				edges{
					cursor
					isLiked
					isFlagged
					node{
						name_normalized
						uuid
						isLiked
						isFlagged
						formalNames{
							totalCount 
							edges{
								cursor
								node{
									uuid
									name_normalized
									isLiked
									isFlagged
								}
							}
						}
						nicknames{
							totalCount
							edges{
								cursor
								node{
									uuid
									name_normalized
									isLiked
									isFlagged
								}
							}
						}
					}
				} 
			}
		  }
		}`
	}
	dispatch(getNameAttempt(name))
	axios.post(`${process.env.REACT_APP_GQL_URL}`, query)
	.then((res: AxiosResponse<GraphQLResponse<GetNameResponse>> ) => {
		if(res.data.errors){
			dispatch(getNameFail(new Error(res.data.errors[0].message)))
		} else if(res.data.data) {
			let resName: NameTypes.Name = {
				uuid: name.toLocaleLowerCase(),
				isLiked: false,
				isFlagged: false,
				name_normalized: name.toLocaleLowerCase()
			}
			if(res.data.data.getName !== null){
				resName = {
					...res.data.data.getName
				}
			} 
			// TODO:
			let [nameMemo, edgeMemo] = updateNameMemo(resName, store.getState().names, store.getState().nicknames.edgeMemo)
			let getNodeToSetResults = getNodesToSet(resName, nameMemo)
			nameMemo = setNodePositions(getNodeToSetResults[0], getNodeToSetResults[1], edgeMemo, store.getState().uiStates.canvas.width, store.getState().uiStates.canvas.width)
			dispatch(getNameSuccess(resName, nameMemo, edgeMemo))
		}
	})
	.catch(err => {
		dispatch(getNameFail(err))
		console.log(err)
	})
}

export const SuggestNamePair = (): any => (dispatch: Dispatch) =>{
	const state = store.getState().nicknames.suggetModal
	if(state.namePair.formalName !== null && state.namePair.nickname !== null){
		const mutation = {
			query: `mutation{
			suggestNickName(
			name:"${state.namePair.formalName}"
			nickName:"${state.namePair.nickname}"
			explanation:"""${state.explanation}"""
			)}`
		}

		dispatch(suggestNameAttempt())
		axios.post(`${process.env.REACT_APP_GQL_URL}`, mutation)
		.then((res: AxiosResponse<any> ) => {
			if(!res.data.data.suggestNickName){
				dispatch(suggestNameFail(new Error("there was an error suggesting this name")))
			} else {
				dispatch(suggestNameSuccess())
				setTimeout(() => {
					dispatch(hideModal("SUGGEST_NAME_MODAL"))
				}, 3000)
			}
		})
		.catch(err => {
			dispatch(suggestNameFail(err))
			console.log(err)
		})
	} else {
		dispatch(suggestNameFail(new Error("You must provide both a formal name and nickname")))
	}
}

export const likeName = (uuid: string): any =>  (dispatch: Dispatch) =>{
	if(store.getState().user.bearerToken){
		const name = store.getState().names[uuid]
		let mutation = {
			query: ""
		}
		
		if(name.isLiked){
			mutation.query = `mutation{
				undoLikeName(
					uuid:"${uuid}"
				){
					name_normalized
					uuid
					isLiked
					isFlagged
				}
			}`
		} else {
			mutation.query = `mutation{
				likeName(
					uuid:"${uuid}"
				){
					name_normalized
					uuid
					isLiked
					isFlagged
				}
			}`
		}

		dispatch(likeNameAttempt())
		axios.post(`${process.env.REACT_APP_GQL_URL}`, mutation)
		.then((res: AxiosResponse<any> ) => {
			if(!res.data.data.likeName && !res.data.data.undoLikeName){
				dispatch(likeNameFail(new Error("there was an error liking this name")))
			} else {
				const resName: NameTypes.Name = {
					...res.data.data.likeName,
					...res.data.data.undoLikeName
				}
				dispatch(likeNameSuccess(resName))
			}
		})
		.catch(err => {
			dispatch(likeNameFail(err))
			console.log(err)
		})
	} else {
		dispatch(showModal("LOGIN_MODAL"))
	}
}

export const likeNamePair = (nameUuid: string, nicknameUuid: string, edgeIsLiked: boolean, uuidToUpdate: string): any =>  (dispatch: Dispatch) =>{
	if(store.getState().user.bearerToken){
		let mutation = {
			query: ""
		}
		if(edgeIsLiked){
			mutation.query = `mutation{
				undoLikeNickname(
					nameUuid:"${nameUuid}"
					nicknameUuid:"${nicknameUuid}"
				){
					name_normalized
					uuid
				}
			}`
		} else {
			mutation.query = `mutation{
				likeNickname(
					nameUuid:"${nameUuid}"
					nicknameUuid:"${nicknameUuid}"
				){
					name_normalized
					uuid
				}
			}`
		}
		dispatch(likeNamePairAttempt())
		axios.post(`${process.env.REACT_APP_GQL_URL}`, mutation)
		.then((res: AxiosResponse<any> ) => {
			if(!res.data.data.likeNickname && !res.data.data.undoLikeNickname){
				dispatch(likeNameFail(new Error("there was an error liking this name")))
			} else {
				dispatch(likeNamePairSuccess(uuidToUpdate))
			}
		})
		.catch(err => {
			dispatch(likeNamePairFail(err))
			console.log(err)
		})
	} else {
		dispatch(showModal("LOGIN_MODAL"))
	}
}

export const flagName = (uuid: string): any =>  (dispatch: Dispatch) =>{
	if(store.getState().user.bearerToken){
		const name = store.getState().names[uuid]
		let mutation = {
			query: ""
		}
		
		if(name.isFlagged){
			mutation.query = `mutation{
				undoFlagName(
					uuid:"${uuid}"
				){
					name_normalized
					uuid
					isLiked
					isFlagged
				}
			}`
		} else {
			mutation.query = `mutation{
				flagName(
					uuid:"${uuid}"
				){
					name_normalized
					uuid
					isLiked
					isFlagged
				}
			}`
		}

		dispatch(flagNameAttempt())
		axios.post(`${process.env.REACT_APP_GQL_URL}`, mutation)
		.then((res: AxiosResponse<any> ) => {
			if(!res.data.data.flagName && !res.data.data.undoFlagName){
				dispatch(flagNameFail(new Error("there was an error liking this name")))
			} else {
				const resName: NameTypes.Name = {
					...res.data.data.flagName,
					...res.data.data.undoFlagName
				}
				dispatch(flagNameSuccess(resName))
			}
		})
		.catch(err => {
			dispatch(flagNameFail(err))
			console.log(err)
		})
	} else {
		dispatch(showModal("LOGIN_MODAL"))
	}
}

export const flagNamePair = (nameUuid: string, nicknameUuid: string, edgeIsFlagged: boolean, uuidToUpdate: string): any =>  (dispatch: Dispatch) =>{
	if(store.getState().user.bearerToken){
		let mutation = {
			query: ""
		}
		if(edgeIsFlagged){
			mutation.query = `mutation{
				undoFlagNickname(
					nameUuid:"${nameUuid}"
					nicknameUuid:"${nicknameUuid}"
				){
					name_normalized
					uuid
				}
			}`
		} else {
			mutation.query = `mutation{
				flagNickname(
					nameUuid:"${nameUuid}"
					nicknameUuid:"${nicknameUuid}"
				){
					name_normalized
					uuid
				}
			}`
		}
		dispatch(flagNamePairAttempt())
		axios.post(`${process.env.REACT_APP_GQL_URL}`, mutation)
		.then((res: AxiosResponse<any> ) => {
			if(!res.data.data.flagNickname && !res.data.data.undoFlagNickname){
				dispatch(flagNameFail(new Error("there was an error liking this name")))
			} else {
				dispatch(flagNamePairSuccess(uuidToUpdate))
			}
		})
		.catch(err => {
			dispatch(flagNamePairFail(err))
			console.log(err)
		})
	} else {
		dispatch(showModal("LOGIN_MODAL"))
	}
}

