import { 
	NameElement,
	NameActionsTypes,
	GET_NAME_SUCCESS,
	LIKE_NAME_SUCCESS,
	FLAG_NAME_SUCCESS,
	LIKE_NAME_PAIR_SUCCESS,
	FLAG_NAME_PAIR_SUCCESS,
	MOVE_NODES,
	MOVE_ONE_NODE,
} from '../actions'

export interface nameMemoState { [uuid: string] : NameElement }

const initialState:nameMemoState = {}

export function names(state = initialState, action: NameActionsTypes):nameMemoState{
	switch(action.type){
		case GET_NAME_SUCCESS:{
			return {
				...state,
				...action.nameMemo
			}
		}
		case MOVE_NODES:{
			return{
				...state,
				...action.nameMemo,
			}
		}
		case MOVE_ONE_NODE:{
			return{
				...state,
				[action.uuid]: action.nameElement
			}
		}
		case LIKE_NAME_SUCCESS:{
			return {
				...state,
				[action.name.uuid]: {
					...state[action.name.uuid],
					isLiked: action.name.isLiked
				}
			}
		}
		case FLAG_NAME_SUCCESS:{
			return {
				...state,
				[action.name.uuid]: {
					...state[action.name.uuid],
					isFlagged: action.name.isFlagged
				}
			}
		}
		case LIKE_NAME_PAIR_SUCCESS:{
			return {
				...state,
				[action.uuidToUpdate]: {
					...state[action.uuidToUpdate],
					edgeIsLiked: !state[action.uuidToUpdate].edgeIsLiked
				}
			}
		}
		case FLAG_NAME_PAIR_SUCCESS:{
			return {
				...state,
				[action.uuidToUpdate]: {
					...state[action.uuidToUpdate],
					edgeIsFlagged: !state[action.uuidToUpdate].edgeIsFlagged
				}
			}
		}
		default:{
			return {
				...state
			}
		} 
	}
}