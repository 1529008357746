import { store } from '../index'
import * as NameTypes from './name.types'
import { Dispatch } from 'redux'


interface Vector {
	x: number
	y: number
}

export const moveNodesAction = (nameMemo: NameTypes.NameMemo): NameTypes.MoveNodes => {
	return {
		type: NameTypes.MOVE_NODES,
		nameMemo: nameMemo,
	}
}

export const moveOneNode = (uuid: NameTypes.uuid, nameElement: NameTypes.NameElement): NameTypes.MoveOneNode => {
	return{
		type: NameTypes.MOVE_ONE_NODE,
		uuid: uuid,
		nameElement: nameElement,
	}
}

export const getLength = (x1: number, y1: number, x2: number, y2: number): number => {
	return Math.sqrt(Math.pow(y1 - y2,2) + Math.pow(x1 - x2, 2))
}

export const moveNodes = (step: number):any => (dispatch: Dispatch) => {

	let newMemo = store.getState().names
	

	for(let id in newMemo){
		if(newMemo.hasOwnProperty(id)){
			if(newMemo[id].current.top !==  newMemo[id].target.top || newMemo[id].current.left !==  newMemo[id].target.left){
				const length = getLength(newMemo[id].current.top, newMemo[id].current.left, newMemo[id].target.top, newMemo[id].target.left)
				let next: { top: number, left: number }
				if( length-step >=0  ){
					next = {
						top: Math.floor( ( (length-step)*(newMemo[id].current.top - newMemo[id].target.top)/length) + newMemo[id].target.top),
						left: Math.floor( ( (length-step)*(newMemo[id].current.left - newMemo[id].target.left)/length) + newMemo[id].target.left),
					}
				} else {
					next = newMemo[id].target
				}	
				newMemo[id].current = next
			}
		}
	}

	dispatch(moveNodesAction(newMemo))
}

// const moveNode = (newMemo: NameTypes.NameMemo, oldMemo: NameTypes.NameMemo, uuid: NameTypes.uuid, subUuid: NameTypes.uuid, step: number, lowLimit: number, highLimit: number): {top: number, left: number} => {
// 	const length = getLength(newMemo[uuid].current.left, newMemo[uuid].current.top, oldMemo[subUuid].current.left, oldMemo[subUuid].current.top)
// 	let next: { top: number, left: number }
// 	if( lowLimit < length && length < highLimit  ){
// 		next = {
// 			top: Math.floor( ( (length-step)*(newMemo[uuid].current.top - oldMemo[subUuid].current.top)/length) + oldMemo[subUuid].current.top),
// 			left: Math.floor( ( (length-step)*(newMemo[uuid].current.left - oldMemo[subUuid].current.left)/length) + oldMemo[subUuid].current.left),
// 		}
// 	} else {
// 		next = oldMemo[uuid].current
// 	}

// 	return next
// }

export const moveNodesByForce = (restingDistance: number, timeSniceLastFrame: number):any => (dispatch: Dispatch) => {
	
	const step = (10 * 60/1000 * timeSniceLastFrame) < 100 ? (10 * 60/1000 * timeSniceLastFrame) : 100
	const nameMemo = store.getState().names
	const edgeMemo = store.getState().nicknames.edgeMemo
	const edges = store.getState().nicknames.edgeElementOrder
	const names = store.getState().nicknames.nameElementOrder
	let newMemo:NameTypes.NameMemo = {}

	
	const springConst = -.001
	const gravConst = -1 * springConst * Math.pow(restingDistance, 3)
	
	names.forEach(uuid => {
		let forceVector = { x: 0, y: 0 }
		if(nameMemo[uuid].relation !== "primaryName"){
			newMemo[uuid] = nameMemo[uuid]
			names.forEach(subUuid => {
				if(uuid !== subUuid){
					forceVector = sumVectors(forceVector, getGravityForceVector(nameMemo, uuid, subUuid, gravConst))
					//newMemo[uuid].current = moveNode(newMemo, nameMemo, uuid, subUuid, -10, 0, 300)
				}	
			})
			
			edges.forEach(edgeId => {
				let edge = edgeMemo[edgeId]
				if(edge.end.uuid === uuid){
					forceVector = sumVectors(forceVector, getSpringForceVector(nameMemo, uuid, edge.start.uuid, springConst))
					//newMemo[uuid].current = moveNode(newMemo, nameMemo, uuid, edge.start.uuid, 20, 150, 1000000000)
				}	
				if(edge.start.uuid === uuid){
					forceVector = sumVectors(forceVector, getSpringForceVector(nameMemo, uuid, edge.end.uuid, springConst))
					//newMemo[uuid].current = moveNode(newMemo, nameMemo, uuid, edge.end.uuid, 20, 150, 1000000000)
				}
			})
			newMemo[uuid].current = {
					top: Math.floor(nameMemo[uuid].current.top + step * forceVector.y),
					left: Math.floor(nameMemo[uuid].current.left + step * forceVector.x),
				}
			// newMemo[uuid].current = {
			// 	top: (Math.abs(step * forceVector.y) > 2) ? Math.floor(nameMemo[uuid].current.top + step * forceVector.y) : nameMemo[uuid].current.top,
			// 	left: (Math.abs(step * forceVector.x) > 2) ?  Math.floor(nameMemo[uuid].current.left + step * forceVector.x) : nameMemo[uuid].current.left,
			// }
		}
	})

	dispatch(moveNodesAction(newMemo))
}

const getGravityForceVector = (oldMemo: NameTypes.NameMemo, uuid: NameTypes.uuid, subUuid: NameTypes.uuid, gravity: number) : Vector => {
	let forceVector = { x: 0, y: 0 }
	const divisor = Math.pow(getLength(oldMemo[uuid].current.left, oldMemo[uuid].current.top, oldMemo[subUuid].current.left, oldMemo[subUuid].current.top),3)

	forceVector.x = gravity * (oldMemo[uuid].current.left - oldMemo[subUuid].current.left) / divisor
	forceVector.y = gravity * (oldMemo[uuid].current.top - oldMemo[subUuid].current.top) / divisor

	return forceVector
}

const getSpringForceVector = (oldMemo: NameTypes.NameMemo, uuid: NameTypes.uuid, subUuid: NameTypes.uuid, gravity: number) : Vector => {
	let forceVector = { x: 0, y: 0 }
	//const distance = getLength(oldMemo[uuid].current.left, oldMemo[uuid].current.top, oldMemo[subUuid].current.left, oldMemo[subUuid].current.top)

	forceVector.x = gravity * (oldMemo[uuid].current.left - oldMemo[subUuid].current.left)
	forceVector.y = gravity * (oldMemo[uuid].current.top - oldMemo[subUuid].current.top)

	return forceVector
}

const sumVectors = (v1: Vector, v2: Vector): Vector => {
	return {x: v1.x + v2.x, y: v1.y + v2.y}
}