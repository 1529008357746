import { 
	NameActionsTypes, 
	Name, 
	GET_NAME_ATTEMPT,
	GET_NAME_SUCCESS,
	GET_NAME_FAIL,
	UPDATE_SUGGEST_NAME_PAIR, 

	LIKE_NAME_ATTEMPT,
	LIKE_NAME_FAIL,
	LIKE_NAME_SUCCESS,

	FLAG_NAME_ATTEMPT,
	FLAG_NAME_FAIL,
	FLAG_NAME_SUCCESS,

	LIKE_NAME_PAIR_ATTEMPT,
	LIKE_NAME_PAIR_FAIL,
	LIKE_NAME_PAIR_SUCCESS,

	FLAG_NAME_PAIR_ATTEMPT,
	FLAG_NAME_PAIR_FAIL,
	FLAG_NAME_PAIR_SUCCESS,
	uuid,
	EdgeMemo,
	NamePair,
	NameNodeRelationOptions,
	getNodesToDisplay
} from '../actions'

export interface nameState {
    current: {
		nameString: string
		name: Name | null
		fetching: boolean
	}
	suggetModal: {
		namePair: NamePair
		relationToSuggest: NameNodeRelationOptions
		explanation: string | undefined
	}
	nameElementOrder: uuid[]
	edgeMemo: EdgeMemo
	edgeElementOrder: string[]
	error: Error | null
}

const initialState:nameState ={
	current:{
		nameString: "", 
		name: null,
		fetching: false,
	},
	suggetModal: {
		namePair: {
			nickname: null,
			formalName: null,
		},
		relationToSuggest: "unrelated",
		explanation: undefined
	},
	nameElementOrder: [],
	edgeMemo: {},
	edgeElementOrder: [],
	error: null,
}

export function nicknames(state = initialState, action: NameActionsTypes):nameState{
	switch(action.type){
        case GET_NAME_ATTEMPT:
			return {
				...state,
				current: {
					...state.current,
					nameString: action.currentName,
					fetching: true,
				}
			}
        case GET_NAME_SUCCESS:{
			
			let [nameElementOrder, edgeElementOrder] = getNodesToDisplay( action.nameMemo, action.edgeMemo)

			return {
				...state,
				current: {
					...state.current,
					name: action.name,
					fetching: false,
				},
				edgeMemo: action.edgeMemo,
				nameElementOrder: nameElementOrder,
				edgeElementOrder:edgeElementOrder,
			}
		}	
		case GET_NAME_FAIL:
			return {
				...state,
				current: {
					...state.current,				
					fetching: false,
				},
				error: action.err
			}
		case UPDATE_SUGGEST_NAME_PAIR:{
			return {
				...state,
				suggetModal: {
					...state.suggetModal,
					namePair: action.namePair,
					relationToSuggest: action.relationToSuggest,
					explanation: action.explanation,
				}
			}
		}
		case LIKE_NAME_ATTEMPT:{
			return {
				...state,
				error: null
			}
		} 
		case LIKE_NAME_FAIL:{
			return {
				...state,
				error: action.err
			}
		} 
		case LIKE_NAME_SUCCESS:{
			return {
				...state,
				error: null
			}
		}
		case FLAG_NAME_ATTEMPT:{
			return {
				...state,
				error: null
			}
		} 
		case FLAG_NAME_FAIL:{
			return {
				...state,
				error: action.err
			}
		} 
		case FLAG_NAME_SUCCESS:{
			return {
				...state,
				error: null
			}
		}
		case LIKE_NAME_PAIR_ATTEMPT:{
			return {
				...state,
				error: null
			}
		} 
		case LIKE_NAME_PAIR_FAIL:{
			return {
				...state,
				error: action.err
			}
		} 
		case LIKE_NAME_PAIR_SUCCESS:{
			return {
				...state,
				error: null
			}
		}
		case FLAG_NAME_PAIR_ATTEMPT:{
			return {
				...state,
				error: null
			}
		} 
		case FLAG_NAME_PAIR_FAIL:{
			return {
				...state,
				error: action.err
			}
		} 
		case FLAG_NAME_PAIR_SUCCESS:{
			return {
				...state,
				error: null
			}
		}
        default:{
			return {
				...state
			}
		}  
    }
}