import { createStore, applyMiddleware, DeepPartial, compose, Middleware } from 'redux'
import ThunnkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer, {AppState} from './reducers'
import { MOVE_NODES } from './actions'

const loggerMiddleware: Middleware =  createLogger({
	predicate: (getState, action) => { return action.type !== MOVE_NODES}
})

const middlewareApplier = process.env.NODE_ENV !== 'development' ? applyMiddleware(ThunnkMiddleware, loggerMiddleware) : applyMiddleware(ThunnkMiddleware)

const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function configureStore(preloadedState?: DeepPartial<AppState>) {
	const store = createStore(
		rootReducer,
		preloadedState,
		composeEnhancers(middlewareApplier)
	)

	return store
}