import { HideModalAction } from './ui.types'

export const LOGIN_ATTEMPT = 'LOGIN_ATTEMPT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const LOGIN_FROM_STORAGE = 'LOGIN_FROM_STORAGE'
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR'
export const REGISTER_ATTEMPT = 'REGISTER_ATTEMPT'
export const REGISTR_SUCCESS = 'REGISTR_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const CLEAR_REGISTER_ERROR = 'CLEAR_REGISTER_ERROR'

/*===== General Types, used as a general type =====*/


/*===== State Types, defines the shape of Redux state =====*/
export interface loginState{
    email: string | null,
    password: string | null,
    bearer_token: string | null,
    nickname?: string, 
    logged_in: boolean
    loggin_error: boolean
    register_error: boolean
}

/*===== Action Types, defines actions for Redux =====*/
export interface LoginAttemptAction {
    type: typeof LOGIN_ATTEMPT
}

export interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS
    token: string,
}

export interface LoginFailAction {
    type: typeof LOGIN_FAIL
    err: Error
}

export interface ClearLoginErrorAction {
    type: typeof CLEAR_LOGIN_ERROR
}

export interface LogoutAction {
    type: typeof LOGOUT
    err: Error
}

export interface LoginFromStorageAction {
    type: typeof LOGIN_FROM_STORAGE
    token: string | null
}

export interface RegisterAttempt {
    type: typeof REGISTER_ATTEMPT
}

export interface RegisterSuccess {
    type: typeof REGISTR_SUCCESS
}

export interface RegisterFail {
    type: typeof REGISTER_FAIL
    err: Error
}

export interface ClearRegisterErrorAction {
    type: typeof CLEAR_REGISTER_ERROR
}

export type UserActionsTypes = LoginAttemptAction | 
LoginSuccessAction | 
LoginFailAction | 
LoginFromStorageAction | 
LogoutAction | 
ClearLoginErrorAction |
RegisterAttempt |
RegisterSuccess |
RegisterFail | 
ClearRegisterErrorAction |
HideModalAction