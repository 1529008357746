import {
    UIActionsTypes,
    UserActionsTypes,
    SHOW_MODAL,
    HIDE_MODAL,
    LOGIN_SUCCESS,
    SET_CANVAS_SIZE,
    SUGGEST_NAME_SUCCESS,
	SUGGEST_NAME_ATTEMPT,
    SUGGEST_NAME_FAIL,
    ON_LOGIN_PAGE,
    MOVE_VIEWPORT,
    GET_NAME_SUCCESS,
    ZOOM_VIEWPORT,
    modals,
    NameActionsTypes
} from '../actions'

export interface modalVisibleStates {
    LOGIN_MODAL: boolean
    REGISTRATION_MODAL: boolean
    SUGGEST_NAME_MODAL: boolean
    ABOUT_MODAL: boolean
}

export interface ViewPort {
    center: {
        top: number
        left: number
    }
    target: {
        top: number
        left: number
    }
    depth: number
    focus: number
}
export interface uiState {
    modalVisibleStates : modalVisibleStates
    canvas: {
        height: number 
        width: number
    },
    viewPort: ViewPort
    isSuggestingName: boolean
    suggestedNameSuccess: boolean
    goToLogin: boolean
    error: Error | null
}

const initialState:uiState ={
    modalVisibleStates: {
        LOGIN_MODAL: false,
        REGISTRATION_MODAL: false,
        SUGGEST_NAME_MODAL: false,
        ABOUT_MODAL: false, 
    },
    canvas: {
        height: 0, 
        width: 0,
    },
    viewPort: {
        center: {
            top: 0,
            left: 0
        },
        target: {
            top: 0,
            left: 0
        },
        depth: 0,
        focus: 1000,
    },
    isSuggestingName: false,
    suggestedNameSuccess: false,
    goToLogin: false,
    error: null
}

const openModal = (modal: modals): modalVisibleStates => {
    let states: modalVisibleStates = closeAllModals()
    states[modal] = true;

    return states
}

const closeAllModals = () => {
    let states: modalVisibleStates = {
        LOGIN_MODAL: false,
        REGISTRATION_MODAL: false,
        SUGGEST_NAME_MODAL: false,
        ABOUT_MODAL: false,
    }

    return states
}

export const getNextDepth = (currentDepth: number, currentFocus: number, delta: number): number => {
    return (currentDepth + delta) + currentFocus <= 0 ? currentDepth : (currentDepth + delta)
}

export function uiStates(state = initialState, action: UIActionsTypes | UserActionsTypes | NameActionsTypes ):uiState{
    switch(action.type){
        case SHOW_MODAL: {
            if(action.modal === 'LOGIN_MODAL'){
                return {
                    ...state,
                    goToLogin: true
                }
            } else {
                return {
                    ...state,
                    modalVisibleStates: openModal(action.modal),
                    isSuggestingName: false,
                    suggestedNameSuccess: false,
                    error: null,
                }
            }
        } 
        case HIDE_MODAL: {
            return {
                ...state,
                modalVisibleStates: closeAllModals()
            }
        }  
        case LOGIN_SUCCESS: {
            return {
                ...state,
                modalVisibleStates: closeAllModals()
            }
        }
        case SET_CANVAS_SIZE: {
            return {
                ...state,
                canvas:{
                    width: action.canvas.width,
                    height: action.canvas.height
                }
            }
        }
        case SUGGEST_NAME_ATTEMPT:{
			return {
				...state,
				isSuggestingName: true,
				suggestedNameSuccess: false,
			}
		}  
		case SUGGEST_NAME_SUCCESS:{
			return {
				...state,
				isSuggestingName: false,
				suggestedNameSuccess: true,
			}
		}  
		case SUGGEST_NAME_FAIL:{
			return {
				...state,
				isSuggestingName: false,
                suggestedNameSuccess: false,
                error: action.err
			}
        } 
        case ON_LOGIN_PAGE:{
            return {
                ...state,
                goToLogin: false
            }
        }
        case MOVE_VIEWPORT:{
            if(action.resetTarget){
                return {
                    ...state,
                    viewPort:{
                        ...state.viewPort,
                        center: action.center,
                        target: action.center
                    }
                }
            } else {
                return {
                    ...state,
                    viewPort:{
                        ...state.viewPort,
                        center: action.center
                    }
                }
            }
        }
        case GET_NAME_SUCCESS:{
            return{
                ...state,
                viewPort:{
                    ...state.viewPort,
                    target: {
                        top: Math.floor(state.viewPort.focus * (action.nameMemo[action.name.uuid].current.top)/(state.viewPort.depth + state.viewPort.focus)),
                        left: Math.floor(state.viewPort.focus * (action.nameMemo[action.name.uuid].current.left)/(state.viewPort.depth + state.viewPort.focus)),
                        // focus * (element.current.left)/(depth+focus) - center.left
                    }
                }
            }
        }
        case ZOOM_VIEWPORT:{
            return {
                ...state,
                viewPort:{
                    ...state.viewPort,
                    depth: getNextDepth(state.viewPort.depth, state.viewPort.focus, action.depth)
                    //Math.floor(state.viewPort.depth + action.depth) + state.viewPort.focus <= 0 ? state.viewPort.depth : Math.floor(state.viewPort.depth + action.depth),
                }
            }
        }
        default:{
            return {
                ...state
            }
        }
    }
}