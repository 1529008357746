import axios, { AxiosResponse } from 'axios'
import { Dispatch } from 'redux'
import * as NameSearchTypes from './name.search.types'
import { toTitleCase } from '../utils/formatting'
import { GraphQLResponse, SearchNamesResponse } from './graphQL.response.types'
// import { store } from '../index'

const searchNameAttempt = (query: string): NameSearchTypes.SearchNameAttempt => {
	return {
		type: NameSearchTypes.SEARCH_NAME_ATTEMPT,
		query: query
	}
}

const searchNameSuccess = (names: string[]): NameSearchTypes.SearchNameSuccess => {
	return {
		type: NameSearchTypes.SEARCH_NAME_SUCCESS,
		names: names
	}
}

const searchNameFail = (err: Error): NameSearchTypes.SearchNameFail => {
	return {
		type: NameSearchTypes.SEARCH_NAME_FAIL,
		err: err
	}
}

export const searchNames = (queryString: string): any =>  (dispatch: Dispatch) =>{
	const query = {
		query: `{
			searchNames(query:"${queryString}")
		}`
	}
	dispatch(searchNameAttempt(queryString))
	axios.post(`${process.env.REACT_APP_GQL_URL}`, query)
	.then((res: AxiosResponse<GraphQLResponse<SearchNamesResponse>> ) => {
		if(res.data.errors){
			dispatch(searchNameFail(new Error(res.data.errors[0].message)))
		} else if(res.data.data) {
			const capitalizedNames = res.data.data.searchNames.map(name => {
				return toTitleCase(name)
			})
			dispatch(searchNameSuccess(capitalizedNames))
		}	
	})
	.catch(err => {
		dispatch(searchNameFail(err))
		console.log(err)
	})
}