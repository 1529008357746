import './index.css'
import React from 'react'
import DrawableComponent from '../DrawableComponent'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import { Name } from '../../actions'
import NameNode from '../NameNode'
import NameEdge from '../NameEdge'
import NodeStack from '../NameNode/NodeStack'
import GraphGrid from '../GraphGrid'
import { moveNodes, moveNodesByForce, moveViewport, getLength, zoomViewport } from '../../actions'
import { ViewPort, getNextDepth } from '../../reducers/ui.reducers'

export interface CanvasPoint {
	top: number
	left: number
}

interface Props {
	height: number
	width: number
	children?: any
	nameString: string
	nameGraph: Name | null
	nameElementArray: string[]
	edgeElementArray: string[]
	viewPort: ViewPort
	moveNodes: typeof moveNodes
	moveNodesByForce: typeof moveNodesByForce
	moveViewport: typeof moveViewport
	zoomViewport: typeof zoomViewport
}

interface State {
	ctx: CanvasRenderingContext2D | null
	mouseStart: CanvasPoint | null
	pastCenter: CanvasPoint | null
	restingDisntance: number
	mouseCurrent: CanvasPoint
}


class Canvas extends React.Component<Props> {
	

	state: State = {
		ctx: null,
		mouseStart: null,
		pastCenter: null,
		restingDisntance: 300,
		mouseCurrent: {
			left: 0,
			top:0,
		}
	}

	lastFrame = new Date()
	thisFrame = new Date()
	rollingAve = 60
	canvasRef: React.RefObject<HTMLCanvasElement>
	
	constructor(props: Props){
		super(props)
		this.drawFrame = this.drawFrame.bind(this);
		this.handleOnMouseDown = this.handleOnMouseDown.bind(this)
		this.handleOnMouseUp = this.handleOnMouseUp.bind(this)
		this.handleOnMouseMove = this.handleOnMouseMove.bind(this)
		this.handleOnTouchStart = this.handleOnTouchStart.bind(this)
		this.handleOnTouchEndOrCancel = this.handleOnTouchEndOrCancel.bind(this)
		this.handleOnTouchMove = this.handleOnTouchMove.bind(this)
		this.handleOnWheel = this.handleOnWheel.bind(this)
		this.canvasRef = React.createRef();
	}

	componentDidMount() {
		const canvas = this.canvasRef.current
		if (canvas){
			this.setState({ctx: canvas.getContext("2d")}, () => {
				if(this.state.ctx){
					this.createCanvasWithCorrectDPI(canvas, this.state.ctx, this.props.width, this.props.height)
				}
			})	
		}
		
		window.requestAnimationFrame(this.drawFrame);
	}

	createCanvasWithCorrectDPI(canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D, width: number, height: number): HTMLCanvasElement{
		
		const ratio = window.devicePixelRatio;
		console.log(ratio)
		canvas.width = width * ratio;
		canvas.height = height * ratio;
		canvas.style.width = `${width}px`
		canvas.style.height = `${height}px`
		ctx.scale(ratio, ratio);
		return canvas;
	}

	handleOnMouseDown(e: React.MouseEvent){
		e.preventDefault()
		if(e.button === 0){
			this.setState({mouseStart: {top: e.screenY, left: e.screenX}, pastCenter: this.props.viewPort.center})
		}
	}

	handleOnMouseUp(e: React.MouseEvent){
		e.preventDefault()
		if(e.button === 0){
			this.setState({mouseStart: null, pastCenter: null})
		}
	}

	handleOnMouseMove(e: React.MouseEvent){
		const { mouseStart } = this.state
 		e.preventDefault()
		if(mouseStart){
			if(this.state.pastCenter){
				const currentMove = {
					top: mouseStart.top - e.screenY,
					left: mouseStart.left - e.screenX,
				}
			
				this.props.moveViewport(this.state.pastCenter.top + currentMove.top, this.state.pastCenter.left + currentMove.left, true)
			}
		}
		if(this.canvasRef.current){
			const rect = this.canvasRef.current.getBoundingClientRect()
			this.setState({mouseCurrent: {top: e.clientY - rect.top , left: e.clientX - rect.left}})
		}
	}

	handleOnWheel(e: React.WheelEvent){
		e.preventDefault()
		const { focus, depth, center } = this.props.viewPort
		//const { height, width } = this.props
		const { mouseCurrent } = this.state
		
		const nextDepth = getNextDepth(depth, focus, e.deltaY)
		const mouse: CanvasPoint = {
			top: mouseCurrent.top + center.top - this.props.height/2,
			left: mouseCurrent.left + center.left - this.props.width/2
		}
		const currentMove = {
			top: mouse.top - ((mouse.top * (focus + depth))/(focus + nextDepth)),//(focus * (mouse.top) / (focus+depth)) - (focus * (mouse.top) / (focus+nextDepth)),
			left: mouse.left - ((mouse.left * (focus + depth))/(focus + nextDepth)),//(focus * (mouse.left) / (focus+depth)) - (focus * (mouse.left) / (focus+nextDepth)),
		}
		this.props.zoomViewport(e.deltaY)
		this.props.moveViewport(center.top - currentMove.top, center.left - currentMove.left, true)
		
	}

	handleOnTouchStart(e: React.TouchEvent){
		if(e.touches.length === 1){
			this.setState({mouseStart: {top: e.touches[0].screenY, left: e.touches[0].screenX}, pastCenter: this.props.viewPort.center})
		}
	}

	handleOnTouchEndOrCancel(e: React.TouchEvent){
		this.setState({mouseStart: null, pastCenter: null})
	}

	handleOnTouchMove(e: React.TouchEvent){
		
		e.preventDefault()
		if(this.state.mouseStart){
			if(this.state.pastCenter && this.state.mouseStart){
				const currentMove = {
					top: this.state.mouseStart.top - e.touches[0].screenY,
					left: this.state.mouseStart.left - e.touches[0].screenX,
				}
			
				this.props.moveViewport(this.state.pastCenter.top + currentMove.top, this.state.pastCenter.left + currentMove.left, true)
			}
		}
		
	}

	checkPointForEquivalence(point1: CanvasPoint, point2: CanvasPoint):boolean {
		return point1.top === point2.top &&  point1.left === point2.left 
	}

	getNextCenter(center: CanvasPoint, target: CanvasPoint): CanvasPoint {
		const step = 15
		const length = getLength(center.left, center.top, target.left, target.top)
		let next: CanvasPoint
		if( length >= step  ){
			next = {
				top: Math.floor( ( (length-step)*(center.top - target.top)/length) + target.top),
				left: Math.floor( ( (length-step)*(center.left - target.left)/length) + target.left),
			}
		} else {
			next = target
		}

		return next
	}

	drawFrame() {
		if(this.state.ctx){
			this.thisFrame = new Date()
			const {ctx, mouseCurrent} = this.state
			ctx.clearRect(0,0,(this.props.width || 100), (this.props.height || 100));
			const { center, depth, focus } =this.props.viewPort

			let numberOfElements = 0
			for(let key in this.refs){
				if (this.refs.hasOwnProperty(key) && key.substring(0,4) === "elem"){
					let elemToDraw: DrawableComponent = this.refs[key] as DrawableComponent
					elemToDraw.drawElement(ctx, this.state.mouseCurrent)
					numberOfElements++
				}
			}
			/** draw frame rate */
			if(process.env.NODE_ENV === 'development'){
				this.rollingAve = Math.floor((1000/(this.thisFrame.getTime() - this.lastFrame.getTime()) + this.rollingAve * 3)/4)
				// eslint-disable-next-line react/no-direct-mutation-state
				ctx.fillStyle = "black";
				// eslint-disable-next-line react/no-direct-mutation-state
				ctx.font = "15px Arial";
				ctx.fillText(`${this.rollingAve}fps, ${numberOfElements} elements`, 50, 50);
				ctx.fillText(`Mouse: { top: ${mouseCurrent.top}, left: ${mouseCurrent.left} }`, 50, 80)
				ctx.fillText(`Center: { top: ${center.top}, left: ${center.left} }`, 50, 110)
				ctx.fillText(`Depth: ${depth}, Focus: ${focus}`, 50, 140)
			}

			if(!this.checkPointForEquivalence(this.props.viewPort.center, this.props.viewPort.target)){
				const nextCenter = this.getNextCenter(this.props.viewPort.center, this.props.viewPort.target)
				this.props.moveViewport(nextCenter.top, nextCenter.left, false)
			}

			this.props.moveNodesByForce(this.state.restingDisntance, this.thisFrame.getTime() - this.lastFrame.getTime())
			window.requestAnimationFrame(this.drawFrame);
			this.lastFrame = this.thisFrame
		}
	}
	
	render(){
		let elementIndex = 0
		return(
			<>
				<div 
					className="canvas-container" 
					onMouseDown={this.handleOnMouseDown} 
					onMouseUp={this.handleOnMouseUp} 
					onMouseMove={this.handleOnMouseMove}
					onMouseLeave={this.handleOnMouseUp}
					onTouchStart={this.handleOnTouchStart}
					onTouchCancel={this.handleOnTouchEndOrCancel}
					onTouchEnd={this.handleOnTouchEndOrCancel}
					onTouchMove={this.handleOnTouchMove}
					onWheel={this.handleOnWheel}
					style={{
						cursor: this.state.mouseStart !== null ? "move" : "default"
					}}
				>
					<GraphGrid ref={`elem${elementIndex++}`}/>
					{
						false && this.props.nameElementArray.map((nameUUID, i) => {
							return (
								<NodeStack 
									uuid={nameUUID}
									ref={`elem${elementIndex++}`}
								/>
								)
						})
					}

					{
						true && this.props.edgeElementArray.map((edgeId, i) => {
							return (
								<NameEdge
									edgeId={edgeId}
									ref={`elem${elementIndex++}`}
								/>)
						})
					}
					{
						true && this.props.nameElementArray.map((nameUUID, i) => {
							return (
								<NameNode 
									uuid={nameUUID}
									ref={`elem${elementIndex++}`}
								/>
								)
						})
					}
					
					<canvas 
						className="main-canvas" 
						ref={this.canvasRef}
						style={{zIndex: -1}} 
					/>
				</div>
			</>
        )
    }
}

const mapStateToProps = (state: AppState) => ({
	nameGraph: state.nicknames.current.name,
	nameString: state.nicknames.current.nameString,
	nameElementArray: state.nicknames.nameElementOrder,
	edgeElementArray: state.nicknames.edgeElementOrder,
	viewPort: state.uiStates.viewPort
});

export default connect(
	mapStateToProps,
	{ moveNodes, moveNodesByForce, moveViewport, zoomViewport }
)(Canvas);

	
	