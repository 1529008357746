import * as UITypes from './ui.types'
import * as NameTypes from './name.types'
import * as NameActions from './name.actions'
import { Dispatch } from 'redux'
import { store } from '../'

export const onLoginPage = (): UITypes.OnLoginPage => {
    return {
        type: UITypes.ON_LOGIN_PAGE
    }
}

export const showModal = (modal: UITypes.modals): UITypes.ShowModalAction => {
    if(store.getState().user.bearerToken || modal === "REGISTRATION_MODAL" || modal === "ABOUT_MODAL"){
        return {
            type: UITypes.SHOW_MODAL,
            modal: modal
        }
    } else {
        return {
            type: UITypes.SHOW_MODAL,
            modal: "LOGIN_MODAL"
        }
    }
    
}

export const hideModal = (modal: UITypes.modals): UITypes.HideModalAction => {
    return {
        type: UITypes.HIDE_MODAL,
        modal: modal
    }
}

export const setCanvasSize = (width: number, height: number): UITypes.SetCanvasSizeAction => {
    return {
        type: UITypes.SET_CANVAS_SIZE,
        canvas: {
            height: height,
            width: width
        }
    }
}

export const moveViewport = (top: number, left: number, resetTarget: boolean): UITypes.MoveViewport => {
    return{
        type: UITypes.MOVE_VIEWPORT,
        center: {
            top: top,
            left: left
        },
        resetTarget: resetTarget
    }
}

export const zoomViewport = (depth: number): UITypes.ZoomViewport => {
    return {
        type: UITypes.ZOOM_VIEWPORT,
        depth: depth 
    }
}

export const showSuggestNamePairModal = (namePair: NameTypes.NamePair, relationToSuggest: "nickname" | "formalName" ):any => ( dispatch: Dispatch) => {
    console.log(namePair)
    dispatch(showModal("SUGGEST_NAME_MODAL"))
    dispatch(NameActions.updateSuggestNamePair(namePair, relationToSuggest, undefined))
}