import * as React from 'react'

const ErrorPage: React.FC = () => {
	return (
		<div>
			this page doesn't exist.
			<br /><br /><br /><br />

			...yet
		</div>
	)
}

export default ErrorPage