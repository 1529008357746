import axios from 'axios'
import {
    UserActionsTypes,
    LOGIN_ATTEMPT,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    LOGIN_FROM_STORAGE,
    REGISTER_ATTEMPT,
    REGISTER_FAIL,
    REGISTR_SUCCESS,
    HIDE_MODAL,
} from '../actions'

export interface userState {
    bearerToken: string | null
    error: Error | null
    attemptingLogin: boolean
    
}

const initialState: userState = {
    bearerToken: null,
    attemptingLogin: false,
    error: null,
}

export function user(state = initialState, action: UserActionsTypes): userState{
    switch(action.type){
        case LOGIN_ATTEMPT:{
            return{
                ...state,
                attemptingLogin: true
            }
        }
        case LOGIN_SUCCESS:{
            axios.defaults.headers.common["Authorization"] = `bearer ${action.token}`
            return{
                ...state,
                error: null,
                bearerToken: action.token,
                attemptingLogin: false
            }
        }
        case LOGIN_FAIL:{
            return{
                ...state,
                error: action.err,
                attemptingLogin: false
            }
        }
        case LOGOUT:{
            delete axios.defaults.headers.common["Authorization"]
            return{
                ...state,
                error: null,
                bearerToken: null,
                attemptingLogin: false
            }
        }
        case LOGIN_FROM_STORAGE:{
            axios.defaults.headers.common["Authorization"] = `bearer ${action.token}`
            return {
                ...state,
                bearerToken: action.token
            }
        }
        case REGISTER_ATTEMPT:{
            return{
                ...state,
                error: null
            }
        }
        case REGISTER_FAIL:{
            return{
                ...state,
                error: action.err
            }
        }
        case REGISTR_SUCCESS:{
            return{
                ...state,
                error: null
            }
        }
        case HIDE_MODAL:{
            return{
                ...state,
                error:null
            }
        }
        default:{
            return{
                ...state
            }
        }
    }
}