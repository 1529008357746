import React, { FormEvent }  from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { hideModal } from '../../actions'
import { registerUser } from '../../actions'

type UpdateTextParam = React.SyntheticEvent<{ value: string }>;

interface Props{
    hideModal: typeof hideModal
    isVisible: boolean
    registerUser: typeof registerUser
    error: Error | null
}

interface state {
    email: string
    password: string
    rememberMe: boolean
    nickname: string | null
    firstName: string | null
    lastName: string | null
}

class LoginModal extends React.Component<Props> {
    
    state = {
        email: '',
        password: '',
        rememberMe: false,
        nickname: null,
        firstName: null,
        lastName: null,
    };

    constructor(props: any){
        super(props)
        this.handleRegister = this.handleRegister.bind(this)
        this.updatePassword = this.updatePassword.bind(this)
        this.updateEmail = this.updateEmail.bind(this)
        this.updateRememberMe = this.updateRememberMe.bind(this)
        this.handleCloseWithState = this.handleCloseWithState.bind(this)
        this.handleSearchResults= this.handleSearchResults.bind(this)
        this.updateName = this.updateName.bind(this)
    }

    handleCloseWithState(){
        //this.props.clearRegisterError()
        this.props.hideModal("REGISTRATION_MODAL")
    }

    updateName(event: UpdateTextParam, nameToUpdate: "nickname" | "firstName" | "lastName"){
        this.setState({[nameToUpdate]: event.currentTarget.value})
    }
    
    updateEmail(event: UpdateTextParam) {
        this.setState({ email: event.currentTarget.value });
    };

    updatePassword(event: UpdateTextParam) {
        this.setState({ password: event.currentTarget.value });
    };

    updateRememberMe(event: React.ChangeEvent<HTMLInputElement>){
        this.setState({ rememberMe: !this.state.rememberMe})
    }

    handleRegister(e: FormEvent){
        e.preventDefault()
        this.props.registerUser(this.state.email, this.state.password, this.state.rememberMe)
    }

    handleSearchResults(name: string){
        this.setState({nickname: name})
    }

    render() {
        return (
            <Modal
                show = {this.props.isVisible}
                onHide = {this.handleCloseWithState}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Create a new account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleRegister}>
                        {/* <Form.Group>
                            <Form.Control onChange={(e: UpdateTextParam) => { this.updateName(e, "nickname")}} type="text" placeholder="Enter Nickname"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control onChange={(e: UpdateTextParam) => { this.updateName(e, "firstName")}} type="text" placeholder="Enter First Name"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control onChange={(e: UpdateTextParam) => { this.updateName(e, "lastName")}} type="text" placeholder="Enter Last Name"/>
                        </Form.Group> */}
                        <Form.Group>
                            <Form.Label>Enter a new email address:</Form.Label>
                            <Form.Control onChange={this.updateEmail} type="email" placeholder="Enter email" required/>
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Enter a new password:</Form.Label>
                            <Form.Control onChange={this.updatePassword} type="password" placeholder="Enter Password" required/>
                            <Form.Text className="text-muted">
                                For clarity sake, we'll never share this either.
                            </Form.Text>
                        </Form.Group>
                        {this.props.error && (
                            <Alert variant="danger">
                                <Alert.Heading>Error</Alert.Heading>
                                <p>
                                    {this.props.error.message}
                                </p>
                            </Alert>
                        )}
                        <Button variant="primary" type="submit">Create & Login</Button>
                        <Form.Group>
                            <Form.Check onChange={this.updateRememberMe} type="checkbox" label="remember me" />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary"onClick={this.handleCloseWithState}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
      }
}

const mapStateToProps = (state: AppState) => ({
    isVisible: state.uiStates.modalVisibleStates.REGISTRATION_MODAL,
    error: state.user.error
});

export default connect(
    mapStateToProps,
    { hideModal, registerUser }
)(LoginModal);