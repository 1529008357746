import { 
	NameSearchActionsTypes,
	SEARCH_NAME_ATTEMPT,
	SEARCH_NAME_SUCCESS,
	SEARCH_NAME_FAIL,
} from '../actions'

interface nameSearchState {
	query: string
	names: string[]
	isLoading: boolean
	error: Error | null
}

const initialState: nameSearchState = {
	query: "",
	names: [],
	isLoading: false,
	error: null,
}

export function nameSearch (state = initialState, action: NameSearchActionsTypes):nameSearchState{
	switch(action.type){
		case SEARCH_NAME_ATTEMPT: {
			return {
				...state,
				isLoading: true,
				query: action.query
			}
		}
		case SEARCH_NAME_SUCCESS: {
			return {
				...state,
				isLoading: false,
				names: action.names
			}
		}
		case SEARCH_NAME_FAIL: {
			return {
				...state,
				isLoading: false,
				error: action.err
			}
		}
		default: {
			return {
				...state,
			}
		}
	}
}