import { combineReducers } from 'redux'
import { nicknames } from './name.reducer'
import { uiStates } from './ui.reducers'
import { user } from './user.reducer'
import { nameSearch } from './name.search.reducer'
import { names } from './names.reducer'

const rootReducer =  combineReducers({
    nicknames,
    uiStates,
    user,
    nameSearch,
    names
});

export default rootReducer

export type AppState = ReturnType<typeof rootReducer>