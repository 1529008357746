import './index.css'
import React, { } from 'react'
import DrawableComponent from '../DrawableComponent'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import { ViewPort } from '../../reducers/ui.reducers'

interface Props {
    width: number
    height: number
    viewPort: ViewPort
    fetchingName: boolean
    ref?: string
}

interface State {
    startAnimatingTime: number
}

class GraphGrid extends DrawableComponent<Props> {
	
	state: State = {
        startAnimatingTime: 0
    }
    
    componentDidUpdate(oldProps: Props){
        if(!oldProps.fetchingName && this.props.fetchingName){
            this.setState({startAnimatingTime: (new Date()).getTime()})
        } 
    }

    drawVerticalLine(ctx: CanvasRenderingContext2D, height: number, width: number, left: number){
        ctx.moveTo(width/2 - left, height);
        ctx.lineTo(width/2 - left, 0);
    }

    drawHorizontalLine(ctx: CanvasRenderingContext2D, height: number, width: number, top: number){
        ctx.moveTo(0, height/2 - top);
        ctx.lineTo(width, height/2 - top);
    }

    getLineSpacing(spacing: number, vanishingDistance: number, focus: number, depth: number): number{
        const lineSpacing = spacing * focus / (focus+depth)
        if( lineSpacing > (vanishingDistance)){
            return lineSpacing
        } else {
            return this.getLineSpacing(spacing*2, vanishingDistance, focus, depth)
        }
    }

    lineWiggle(i: number, start: number, time: number, amplitude: number, animate: boolean) : number {
        return animate ? amplitude * Math.sin( ((i % 2) === 1 ? 1 : -1 ) * (time - start)/500) : 0
    }

    drawElement(ctx: CanvasRenderingContext2D, mouse: {top: number, left: number}){
        const { center, depth, focus } =this.props.viewPort
        const { height, width, fetchingName} = this.props
        const lineSpacing = this.getLineSpacing(160, 80, focus, depth)
        const time = (new Date()).getTime()

        ctx.fillStyle = "#f2f5f4";
        ctx.fillRect(0, 0, width, height);
        
        ctx.beginPath();
        // x-axis
        this.drawVerticalLine(ctx, height, width, center.left)
        const verticalLines = Math.ceil((width/2 - center.left)/lineSpacing)
        for(let i = 1; i < verticalLines; i++){
            this.drawVerticalLine(ctx, height, width, center.left + (i * lineSpacing) + this.lineWiggle(i, this.state.startAnimatingTime, time, 20, fetchingName))

        }
        for(let i = 1; i < (Math.ceil(width/lineSpacing)) - verticalLines + 1; i++){
            this.drawVerticalLine(ctx, height, width, center.left - (i * lineSpacing) + this.lineWiggle(i, this.state.startAnimatingTime, time, 20, fetchingName))
        } 
        // y-axis
        const horizontalLines = Math.ceil((height/2 - center.top)/lineSpacing)
        
        this.drawHorizontalLine(ctx, height, width, center.top)
        for(let i = 1; i < horizontalLines; i++){
            this.drawHorizontalLine(ctx, height, width, center.top + (i * lineSpacing) + this.lineWiggle(i, this.state.startAnimatingTime, time, 20, fetchingName))
        }
        for(let i = 1; i < (Math.ceil(height/lineSpacing)) - horizontalLines + 1; i++){
            this.drawHorizontalLine(ctx, height, width, center.top - (i * lineSpacing) + this.lineWiggle(i, this.state.startAnimatingTime, time, 20, fetchingName))
        } 

        ctx.lineWidth = 1;
        ctx.strokeStyle = "#4d9467"
        // ctx.strokeStyle = "black"
        ctx.stroke();
}

    render(){
        return (<></>)
    }
}

const mapStateToProps = (state: AppState) => ({
    width: state.uiStates.canvas.width,
    height: state.uiStates.canvas.height,
    viewPort: state.uiStates.viewPort,
    fetchingName: state.nicknames.current.fetching
});

export default connect(
	mapStateToProps,
	{ 

	},
	null,
	{ forwardRef: true },
)(GraphGrid)