import React, { FormEvent } from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import { searchNames } from '../../actions'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { Redirect } from 'react-router-dom'
import { Col, Form, Button  } from 'react-bootstrap'

interface Props{
	searchNames: typeof searchNames
	query: string
	names: string[]
    isLoading: boolean
}

interface State{
	nextName: string | null
	searchBarText: string
}
class NameSearchBar extends React.Component<Props> {
	state = {
		nextName: null,
		searchBarText: ""
	}

	constructor(props: Props){
		super(props)
		this.handleSearch = this.handleSearch.bind(this)
		this.handleOnChange = this.handleOnChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleOnInputChange = this.handleOnInputChange.bind(this)
		this.handleKeyPress = this.handleKeyPress.bind(this)
	}

	handleSearch(query: string){
	    this.props.searchNames(query)
	}

	handleOnChange(selected: string[]){
        console.log("in handle input change")
        console.log(selected)
		if(selected.length>0){
			this.setState({nextName: selected[0]})
		}
	}

	handleSubmit(e: FormEvent){
		e.preventDefault()
		this.setState({nextName: this.state.searchBarText})
	}

	handleOnInputChange(input: string, e: Event){
		console.log(input)
		this.setState({searchBarText: input})
	}

	handleKeyPress(e: Event) {
		const keyboardE = e as KeyboardEvent
		console.log(keyboardE.key)
		if(keyboardE.key==="Enter" || keyboardE.key==="enter"){
			//this.setState({nextName: this.state.searchBarText})
		} 
	}
	
	render(){
		if (this.state.nextName){
			this.setState({nextName: null})
			return <Redirect push exact to={`/name/${this.state.nextName}`} />;
		}
		return(
			<Form inline onSubmit={this.handleSubmit} >
				<Form.Row>
					<Col>
						<AsyncTypeahead
							onInputChange = {this.handleOnInputChange}
							selectHintOnEnter = {true}
							onKeyDown={this.handleKeyPress}
							onChange = {this.handleOnChange}
							allowNew={false}
							multiple={false}
							onSearch={this.handleSearch}
							placeholder = "Search names"
							options = {this.props.names}
							isLoading = {this.props.isLoading}
							minLength = {1}
							filterBy = { () => {
								return true
							}}
							renderMenuItemChildren={(name, props, index) => (
								<div id={name} key={`res_${index}`} >
									<span>{name}</span>
								</div>
							)}
						/>
					</Col>
					<Col>
						<Button variant="outline-success" type="submit"> Search </Button>
					</Col>
				</Form.Row>
			</Form>
		)
	}
}

const mapStateToProps = (state: AppState) => ({
	query: state.nameSearch.query,
	names: state.nameSearch.names,
    isLoading: state.nameSearch.isLoading,
});

export default connect(
	mapStateToProps,
	{ searchNames },
)(NameSearchBar)