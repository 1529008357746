import * as React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import { getName, GetName } from '../../actions'
import Canvas from '../Canvas'
import SuggestNamePairModal from '../Modals/SuggestNamePairModal'

interface Props {
	match: {
		params: {
			name?: string
		}
	}
	getName: GetName
	nameString: string
	width: number
	height: number
}



class NameGraph extends React.Component<Props> {

	constructor(props: Props){
		super(props)
		this.props.getName((this.props.match.params.name) ? this.props.match.params.name : "Diana")
	}
	

	componentDidUpdate(oldProps: Props){
		if(this.props.nameString.toLowerCase() !== ((this.props.match.params.name) ? this.props.match.params.name : "Diana").toLowerCase()){
			this.props.getName((this.props.match.params.name) ? this.props.match.params.name : "Diana")
		}
	}

	render() {
		return (
			<>
				<Canvas width={this.props.width} height = {this.props.height} />
				<SuggestNamePairModal />
			</>
		);
	  }
}

const mapStateToProps = (state: AppState) => ({
	nameString: state.nicknames.current.nameString,
	width: state.uiStates.canvas.width,
	height: state.uiStates.canvas.height,
});

export default connect(
	mapStateToProps,
	{ getName }
)(NameGraph);
