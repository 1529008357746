export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const SET_CANVAS_SIZE = 'SET_CANVAS_SIZE'
export const GO_TO_LOGIN_PAGE = 'GO_TO_LOGIN'
export const ON_LOGIN_PAGE = 'ON_LOGIN_PAGE'
export const MOVE_VIEWPORT = 'MOVE_VIEWPORT'
export const ZOOM_VIEWPORT = 'ZOOM_VIEWPORT'

export type modals =  'LOGIN_MODAL' | 'REGISTRATION_MODAL' | 'SUGGEST_NAME_MODAL' | 'ABOUT_MODAL'

/*===== General Types, used as a general type =====*/


/*===== Action Types, defines actions for Redux =====*/
export interface ShowModalAction {
    type: typeof SHOW_MODAL
    modal: modals
}

export interface HideModalAction {
    type: typeof HIDE_MODAL
    modal: modals
}

export interface SetCanvasSizeAction {
    type: typeof SET_CANVAS_SIZE
    canvas: {
        height: number 
        width: number
    }
}

export interface GoToLoginPage {
    type: typeof GO_TO_LOGIN_PAGE
}

export interface OnLoginPage {
    type: typeof ON_LOGIN_PAGE
}

export interface MoveViewport {
    type: typeof MOVE_VIEWPORT
    center: {
        top: number
        left: number
    }
    resetTarget: boolean
}

export interface ZoomViewport {
    type: typeof ZOOM_VIEWPORT
    depth: number
}

export type UIActionsTypes = ShowModalAction | HideModalAction | SetCanvasSizeAction | GoToLoginPage | OnLoginPage | MoveViewport | ZoomViewport