import './index.css'
import React, { } from 'react'
import DrawableComponent from '../DrawableComponent'
import { Redirect } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import { NameMemo, NameElement } from '../../actions'
import { toTitleCase } from '../../utils'
import {
	Name,
	showSuggestNamePairModal,
	likeName,
	flagName,
	likeNamePair,
	flagNamePair,
	moveOneNode,
 } from '../../actions'
import { ViewPort } from '../../reducers/ui.reducers'

interface Props {
	uuid: string
	width: number
	height: number
	nameMemo: NameMemo
	currentName: Name | null
	showSuggestNamePairModal: typeof showSuggestNamePairModal
	likeName: typeof likeName
	flagName: typeof flagName
	likeNamePair: typeof likeNamePair
	flagNamePair: typeof flagNamePair
	moveOneNode: typeof moveOneNode
	viewPort: ViewPort
	ref?: string
}
interface State {
	hovering: boolean,
	inElement: boolean, 
	newName: string | null
	menuOpen: boolean
}

class NameNode extends DrawableComponent<Props> {
	
	state: State = {
		hovering: false,
		inElement: false,
		newName: null,
		menuOpen: false,
	}

	constructor(props: Props){
		super(props)
		this.handleClick = this.handleClick.bind(this)
		this.handleDropdown = this.handleDropdown.bind(this)
		this.handleSuggestNickname = this.handleSuggestNickname.bind(this)
		this.handleSuggestFormalName = this.handleSuggestFormalName.bind(this)
		this.handleLikeName = this.handleLikeName.bind(this)
		this.handleLikeNickname = this.handleLikeNickname.bind(this)
		this.handleLikeFormalName = this.handleLikeFormalName.bind(this)
		this.handleFlagName = this.handleFlagName.bind(this)
		this.handleFlagNickname = this.handleFlagNickname.bind(this)
		this.handleFlagFormalName = this.handleFlagFormalName.bind(this)
		this.getCenter = this.getCenter.bind(this)
		this.getElement = this.getElement.bind(this)

	}

	getLength(x1: number, y1: number, x2: number, y2: number): number {
		return Math.sqrt(Math.pow(y1 - y2,2) + Math.pow(x1 - x2, 2))
	}

	getCenter():{x: number, y:number } {
		const element = this.props.nameMemo[this.props.uuid]
		const { focus, depth, center } = this.props.viewPort
		return {
			x: focus * (element.current.left)/(depth+focus) - center.left + this.props.width/2,
			y: focus * (element.current.top)/(depth+focus) - center.top + this.props.height/2 - (3 * Number(this.state.hovering))
		}
	}

	getElement():NameElement{
		let element = {...this.props.nameMemo[this.props.uuid]}
		const { focus, depth } = this.props.viewPort
		if(this.state.hovering && depth > 0){

		} else {
			element.radius = focus * (element.radius)/(depth + focus)
		}
		
		return element
	}

	drawElement(ctx: CanvasRenderingContext2D, mouse: {top: number, left: number}){
		const { focus, depth } = this.props.viewPort
		const element = this.getElement()
		let colors = {
			nickname:'#4d94ff',
			primaryName: '#e6c300',
			formalName: '#00cc00',
			unrelated: "#9e9e9e"
		}

		ctx.beginPath();
		//ctx.shadowBlur=20 + (3 * Number(this.state.hovering));
		//ctx.shadowOffsetY = this.state.hovering ? 3 : 0
		//ctx.shadowColor= colors[element.relation]
		const center = this.getCenter()
		if(this.getLength(center.x, center.y, mouse.left, mouse.top) <= element.radius && !this.state.hovering){
			this.setState({hovering: true})
		} else if (!(this.getLength(center.x, center.y, mouse.left, mouse.top) <= element.radius) && this.state.hovering && !this.state.inElement){
			this.setState({hovering: false})
		}

		ctx.arc(center.x, center.y, element.radius , 0, 2 * Math.PI);
		ctx.lineWidth = 5;
		ctx.strokeStyle = colors[element.relation]
		ctx.stroke();
		ctx.fillStyle = '#ffffff';
		ctx.fill();

		if(!this.state.hovering){
			const fontSize = Math.floor(focus * (16)/(depth+focus))
			ctx.fillStyle = "black";
			ctx.font = `${fontSize}px Arial`;
			ctx.textAlign = 'center'
			ctx.textBaseline = "middle"
			const formatedNameString = `${toTitleCase(element.nameString || "undefined")}`
			ctx.fillText(formatedNameString, center.x, center.y );
			ctx.textAlign = 'start'
			ctx.textBaseline = 'bottom'
		}
		// ctx.fillStyle = "black";
		// ctx.fillText(`{top: ${center.y}, left: ${center.x}}`, center.x, center.y + 30 );

		ctx.shadowBlur=0;
		ctx.shadowOffsetY = 0;
		ctx.strokeStyle = "black"

		// ctx.beginPath();
		// ctx.arc(element.current.left + this.props.width/2, element.current.top + this.props.height/2 - (3 * Number(this.state.hovering)), element.radius - 5 , 0, Math.PI/2)
		// ctx.lineWidth = 3
		// ctx.strokeStyle = "#bfbfbf"
		// ctx.stroke();

		// ctx.shadowBlur=0;
		// ctx.shadowOffsetY = 0;
		// ctx.strokeStyle = "black"
	}

	handleClick(){
		this.setState({newName: this.props.nameMemo[this.props.uuid].nameString})
	}

	handleSuggestNickname(){
		this.props.showSuggestNamePairModal({
			nickname: null,
			formalName: toTitleCase(this.props.nameMemo[this.props.uuid].nameString || "undefined") 
		}, "nickname")
	}

	handleSuggestFormalName(){
		this.props.showSuggestNamePairModal({
			nickname: toTitleCase(this.props.nameMemo[this.props.uuid].nameString || "undefined") ,
			formalName: null
		}, "formalName")
	}

	handleLikeName(){
		this.props.likeName(this.props.uuid)
	}

	handleLikeNickname(){
		if(this.props.currentName){
			this.props.likeNamePair(this.props.currentName.uuid, this.props.uuid, this.props.nameMemo[this.props.uuid].edgeIsLiked, this.props.uuid)
		}
	}

	handleLikeFormalName(){
		if(this.props.currentName){
			this.props.likeNamePair(this.props.uuid, this.props.currentName.uuid, this.props.nameMemo[this.props.uuid].edgeIsLiked, this.props.uuid)
		}
	}

	handleFlagName(){
		this.props.flagName(this.props.uuid)
	}

	handleFlagNickname(){
		if(this.props.currentName){
			this.props.flagNamePair(this.props.currentName.uuid, this.props.uuid, this.props.nameMemo[this.props.uuid].edgeIsFlagged, this.props.uuid)
		}
	}

	handleFlagFormalName(){
		if(this.props.currentName){
			this.props.flagNamePair(this.props.uuid, this.props.currentName.uuid, this.props.nameMemo[this.props.uuid].edgeIsFlagged, this.props.uuid)
		}
	}

	handleDropdown(isOpen: boolean, event: React.SyntheticEvent<Dropdown>, metadata: { source: 'select' | 'click' | 'rootClose' | 'keydown' },){
		if(event.stopPropagation){
			event.stopPropagation()
		}
	}

	render(){
		if(!this.state.hovering){
			return (<></>)
		}
		const { focus, depth } = this.props.viewPort
		let element = this.getElement()
		const sideLength = (element.radius)
		if (this.state.newName){
			this.setState({newName: null})
			return <Redirect push exact to={`/name/${this.state.newName}`} />;
		}
		const center = this.getCenter()
		const style: React.CSSProperties = {
			left: center.x - sideLength,
			top: center.y - sideLength,
			borderRadius:sideLength,
			width: sideLength * 2,
			height: sideLength * 2,
			cursor: 'pointer',
			fontFamily: 'Arial'
		}
		const renderMore = (this.state.hovering && (element.relation !== "unrelated"))
		return(
			
			<div 
				className="name-element"
				style = {style}
				onMouseEnter = {(e) => { this.setState({inElement: true}) }}
				onMouseLeave = {(e) => { this.setState({inElement: false}) }}
				onClick = {this.handleClick}
			>	
				<table>
					<tbody>
						<tr>
							<td className="empty-cell"/>
						</tr>
						<tr className="centered-text" style={{fontSize: depth > 0 ? 16 : Math.floor(focus * (16)/(depth+focus)) }}>
							<td>
								{toTitleCase(element.nameString || "undefined")}
							</td>
						</tr>				
					{  renderMore &&
						<tr>
							<td>
								<Dropdown onToggle={this.handleDropdown}>
									<Dropdown.Toggle 
										variant="link" size="sm" id="dropdown-basic" 
									>
										more
									</Dropdown.Toggle>

									<Dropdown.Menu>
										{	element.relation === "primaryName" && (
											<>
												<Dropdown.Item as="button" onClick={this.handleSuggestNickname}>suggest nickname</Dropdown.Item>
												<Dropdown.Item as="button" onClick={this.handleSuggestFormalName}>suggest formal name </Dropdown.Item>
												<Dropdown.Divider />
												<Dropdown.Item as="button" onClick={this.handleLikeName}>{element.isLiked ? "Name liked" : "Like Name"}</Dropdown.Item>
												<Dropdown.Item as="button" onClick={this.handleFlagName}>{element.isFlagged ? "Name reported" : "Report Name"}</Dropdown.Item>
											</>
										)}
										{	element.relation === "nickname" && (
											<>
												<Dropdown.Item as="button" onClick={this.handleLikeNickname}>{element.edgeIsLiked ? "Nickname approved" : "Approve of nickname" }</Dropdown.Item>
												<Dropdown.Item as="button" onClick={this.handleFlagNickname}>{element.edgeIsFlagged ? "Nickname reported" : "Report nickname" }</Dropdown.Item>
											</>
										)}
										{	element.relation === "formalName" && (
											<>
												<Dropdown.Item as="button" onClick={this.handleLikeFormalName}>{element.edgeIsLiked ? "Formal name approved" : "Approve of formal name" }</Dropdown.Item>
												<Dropdown.Item as="button" onClick={this.handleFlagFormalName}>{element.edgeIsFlagged ? "Formal name reported" : "Report formal name" }</Dropdown.Item>
											</>
										)}
									</Dropdown.Menu>
								</Dropdown>
							</td>
						</tr>
					}
					{ 
						!renderMore &&
						<tr className="centered-text">
							<td className="empty-cell"/>
						</tr>
					}
					</tbody>
				</table>
			</div>
		)
	}
}


const mapStateToProps = (state: AppState) => ({
	nameMemo: state.names,
	width: state.uiStates.canvas.width,
	height: state.uiStates.canvas.height,
	currentName: state.nicknames.current.name,
	viewPort: state.uiStates.viewPort,
	fetchingName: state.nicknames.current.fetching,
});

export default connect(
	mapStateToProps,
	{ 
		showSuggestNamePairModal, 
		likeName,
		flagName,
		likeNamePair,
		flagNamePair,
		moveOneNode,
	},
	null,
	{ forwardRef: true },
)(NameNode)
