import React, { FormEvent }  from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { hideModal, showModal, NameNodeRelationOptions, SuggestNamePair, updateSuggestNamePair, } from '../../actions'

interface Props{
    hideModal: typeof hideModal
    SuggestNamePair: typeof SuggestNamePair
    suggestError: Error | null
    isVisible: boolean
    nickname: string | null
    formalName: string | null
    explanation: string | undefined
    relationToSuggest: NameNodeRelationOptions
    updateSuggestNamePair: typeof updateSuggestNamePair
    isSuggestingName: boolean
    suggestedNameSuccess: boolean
    error: Error | null
}

class SuggestNamePairModal extends React.Component<Props> {

    constructor(props: any){
        super(props)
        this.handleCloseWithState = this.handleCloseWithState.bind(this)
        this.handleUpdateExplination = this.handleUpdateExplination.bind(this)
        this.handleUpdateName = this.handleUpdateName.bind(this)
        this.handleSubmitSuggestion = this.handleSubmitSuggestion.bind(this)
    }

    handleUpdateName(event: React.SyntheticEvent<{ value: string }>){
        let namePair = {
            nickname:this.props.nickname,
            formalName: this.props.formalName,
        }
        let relation: "nickname" | "formalName" = "nickname"
        if(this.props.relationToSuggest === "nickname"){
            namePair.nickname = event.currentTarget.value
            relation = "nickname"
        } else {
            namePair.formalName = event.currentTarget.value
            relation = "formalName"
        }
        this.props.updateSuggestNamePair(namePair, relation, this.props.explanation)
    }

    handleUpdateExplination(event: React.SyntheticEvent<{ value: string }>){
        this.props.updateSuggestNamePair({
            nickname:this.props.nickname,
            formalName: this.props.formalName,
        },
        (this.props.relationToSuggest === "nickname") ? "nickname" : "formalName",
        event.currentTarget.value
        )
    }

	handleCloseWithState(){
        this.props.hideModal("SUGGEST_NAME_MODAL")
    }

    handleSubmitSuggestion(e: FormEvent){
        e.preventDefault()
        this.props.SuggestNamePair()
    }

	render() {
        let primaryName: string | null = null
        let newNameValue : string | undefined = undefined
        let RelationStringForTitle = ""
        if(this.props.relationToSuggest === "nickname"){
            primaryName = this.props.formalName
            RelationStringForTitle = "nickname"
            newNameValue = this.props.nickname ? this.props.nickname : undefined
        } else {
            primaryName = this.props.nickname
            RelationStringForTitle = "formal name"
            newNameValue = this.props.formalName ? this.props.formalName : undefined
        }
        return (
			<Modal
                show = {this.props.isVisible}
                onHide = {this.handleCloseWithState}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
				<Modal.Header closeButton>
                    <Modal.Title>
                        {`Suggest a ${RelationStringForTitle} for ${primaryName}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmitSuggestion}>
                            <Form.Group>
                                <Form.Control
                                    onChange={this.handleUpdateName}
                                    type="text"
                                    placeholder={`${RelationStringForTitle} for ${primaryName}`}
                                    value={newNameValue}
                                    required = {true}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    onChange={this.handleUpdateExplination}
                                    as="textarea"
                                    rows={5}
                                    placeholder="Please add an explination for this suggestion. This will help the admin team evaluate if the name should be added to the Nickname Graph."
                                    value={this.props.explanation}
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit" disabled={this.props.isSuggestingName}>Submit suggestion</Button>
                    </Form>
                    {this.props.suggestedNameSuccess && (
                            <Alert dismissible variant="success">
                                <Alert.Heading>Success</Alert.Heading>
                                <p>
                                    this {RelationStringForTitle} has been submitted for approval. Thank you!
                                </p>
                            </Alert>
                    )}
                    {this.props.error && (
                            <Alert dismissible variant="danger">
                                <Alert.Heading>Error</Alert.Heading>
                                <p>
                                    there was an error: {this.props.error.message}
                                </p>
                            </Alert>
                    )}
                </Modal.Body>

			</Modal>
		)
		
	}
}

const mapStateToProps = (state: AppState) => ({
    isVisible: state.uiStates.modalVisibleStates.SUGGEST_NAME_MODAL,
    suggestError: state.user.error,
    nickname: state.nicknames.suggetModal.namePair.nickname,
    formalName: state.nicknames.suggetModal.namePair.formalName,
    explanation: state.nicknames.suggetModal.explanation,
    relationToSuggest: state.nicknames.suggetModal.relationToSuggest,
    isSuggestingName: state.uiStates.isSuggestingName,
    suggestedNameSuccess: state.uiStates.suggestedNameSuccess,
    error: state.uiStates.error,
});

export default connect(
    mapStateToProps,
    { hideModal, showModal, SuggestNamePair, updateSuggestNamePair }
)(SuggestNamePairModal);