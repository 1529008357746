import React from 'react';
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux';
import { AppState } from '../../reducers';
import { hideModal, } from '../../actions'

interface Props{
    hideModal: typeof hideModal
    isVisible: boolean
}

class AboutModal extends React.Component<Props> {

    constructor(props: any){
        super(props)
        this.handleCloseWithState = this.handleCloseWithState.bind(this)
    }

    handleCloseWithState(){
        this.props.hideModal("ABOUT_MODAL")
    }

    render() {
        return (
			<Modal
                show = {this.props.isVisible}
                onHide = {this.handleCloseWithState}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
				<Modal.Header closeButton>
                    <Modal.Title>
                        About Nickname Graph
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        In <a href="https://en.wikipedia.org/wiki/Mathematics">mathematics</a>, 
                        and more specifically in <a href="https://en.wikipedia.org/wiki/Graph_theory">graph theory</a>, a <b><a href="https://en.wikipedia.org/wiki/Graph_(discrete_mathematics)">graph</a></b> is a structure amounting 
                        to a set of objects in which some pairs of the objects are in some sense "related". The objects 
                        correspond to mathematical abstractions called vertices (also called nodes or points) and each 
                        of the related pairs of vertices is called an edge (also called link or line)
                    </p>
                    <p>
                        In the Nickname Graph, each Node is a distinict spelling of a name. It's relation to other names are shown by
                        a directed arrow. for example, Nicholas -> Nick 
                    </p>
                    <p>
                        If you'd like to suggest a name or nickname to add to the Nickname Graph, simple click on the name and selected 
                        to add a formal name or a nickname. The name will be added to a queue for an admin to approve.
                    </p>
                    <p>
                        Thank you to everyone who contributes to the Nickname Graph, it's immensely helpful in creating a complete map 
                        of each name and their relation to others.
                    </p>
                </Modal.Body>
			</Modal>
		)
    }

}

const mapStateToProps = (state: AppState) => ({
    isVisible: state.uiStates.modalVisibleStates.ABOUT_MODAL,
});

export default connect(
    mapStateToProps,
    { hideModal }
)(AboutModal);