import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux'
import * as UserTypes from './user.types'

function loginAttempt(): UserTypes.LoginAttemptAction{
    return{
        type: UserTypes.LOGIN_ATTEMPT,
    }
}

function loginSuccess(token: string): UserTypes.LoginSuccessAction{
    return{
        type: UserTypes.LOGIN_SUCCESS,
        token: token,
    }
}

function loginFail(err: Error): UserTypes.LoginFailAction{
    return{
        type: UserTypes.LOGIN_FAIL,
        err: err
    }
}

function registerAttempt(): UserTypes.RegisterAttempt{
    return{
        type: UserTypes.REGISTER_ATTEMPT,
    }
}

function registerSuccess(): UserTypes.RegisterSuccess{
    return{
        type: UserTypes.REGISTR_SUCCESS
    }
}

function registerFail(err: Error): UserTypes.RegisterFail{
    return{
        type: UserTypes.REGISTER_FAIL,
        err: err
    }
}

export function clearLoginError(): UserTypes.ClearLoginErrorAction{
    return{
        type: UserTypes.CLEAR_LOGIN_ERROR,
    }
}

export function clearRegisterError(): UserTypes.ClearRegisterErrorAction{
    return{
        type: UserTypes.CLEAR_REGISTER_ERROR,
    }
}

export const logout = () => {
    localStorage.removeItem('bearer_token')
    sessionStorage.removeItem('bearer_token')
    return{
        type: UserTypes.LOGOUT
    }
}

export const loginFromStorage = (): UserTypes.LoginFromStorageAction => {
    let token: string | null = localStorage.getItem("bearer_token") || sessionStorage.getItem('bearer_token')
    return{
        type: UserTypes.LOGIN_FROM_STORAGE,
        token: token
    }
} 

export const loginUser = (email: string, password: string, rememberMe: boolean) => (dispatch: Dispatch) => {
    if(email && password){
        const query = {
            query: `{
                getAuthToken(creds:{
                    email:"${email}"
                    password:"${password}"
                }){
                    token
                }
            }`
        }
        dispatch(loginAttempt())
        axios.post(`${process.env.REACT_APP_GQL_URL}`, query)
	    .then((res: AxiosResponse<any> ) => {
            if(!res.data.data.getAuthToken){
                dispatch(loginFail(new Error("No user info was found")))
            } else {
                if(rememberMe){
                    sessionStorage.setItem('bearer_token', res.data.data.getAuthToken.token);
                } else {
                    localStorage.setItem('bearer_token', res.data.data.getAuthToken.token);
                }
                dispatch(loginSuccess(res.data.data.getAuthToken.token))
            }
        })
        .catch((err: Error) => {
            dispatch(loginFail(err))
            console.log(err)
        })
        

    } else {
        dispatch(loginFail(new Error("you must supply an email and password")))
    }
};

export const registerUser = (email: string, password: string, rememberMe: boolean) => (dispatch: Dispatch) => {
    if(email && password){
        dispatch(registerAttempt())
        const mutation = {
            query: `mutation{
                createUser(input:{
                    email:"${email}"
                    password:"${password}"
                }){
                    uuid
                }
            }`
        }

        console.log(mutation)

        axios.post(`${process.env.REACT_APP_GQL_URL}`, mutation)
        .then((res: AxiosResponse<any> ) => {
            console.log(res.data)
            if("errors" in res.data){
                dispatch(registerFail(new Error(res.data.errors[0].message)))
            } else if(!res.data.data.createUser.uuid){
                dispatch(registerFail(new Error("This user couldn't be created")))
            } else {
                dispatch(registerSuccess())
                loginUser(email, password, rememberMe)(dispatch)
            }
        })
        .catch((err: Error) => {
            dispatch(loginFail(err))
            console.log(err)
        })
    } else {
        dispatch(registerFail(new Error("you must supply an email and password")))
    }
};

export const updateUser = (nickname: string | null, firstName: string | null, lastName: string | null) => (dispatch: Dispatch) => {

}
