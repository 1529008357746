export const GET_NAME_ATTEMPT = 'GET_NAME_ATTEMPT'
export const GET_NAME_SUCCESS = 'GET_NAME_SUCCESS'
export const GET_NAME_FAIL = 'GET_NAME_FAIL'

export const MOVE_NODES = 'MOVE_NODES'
export const MOVE_ONE_NODE = 'MOVE_ONE_NODE'

export const UPDATE_SUGGEST_NAME_PAIR = 'UPDATE_SUGGEST_NAME_PAIR'
export const SUGGEST_NAME_ATTEMPT = "SUGGEST_NAME_ATTEMPT"
export const SUGGEST_NAME_SUCCESS = "SUGGEST_NAME_SUCCESS"
export const SUGGEST_NAME_FAIL = "SUGGEST_NAME_FAIL"

export const LIKE_NAME_ATTEMPT = 'LIKE_NAME_ATTEMPT'
export const LIKE_NAME_SUCCESS = 'LIKE_NAME_SUCCESS'
export const LIKE_NAME_FAIL = 'LIKE_NAME_FAIL'

export const LIKE_NAME_PAIR_ATTEMPT = 'LIKE_NAME_PAIR_ATTEMPT'
export const LIKE_NAME_PAIR_SUCCESS = 'LIKE_NAME_PAIR_SUCCESS'
export const LIKE_NAME_PAIR_FAIL = 'LIKE_NAME_PAIR_FAIL'

export const FLAG_NAME_ATTEMPT = 'FLAG_NAME_ATTEMPT'
export const FLAG_NAME_SUCCESS = 'FLAG_NAME_SUCCESS'
export const FLAG_NAME_FAIL = 'FLAG_NAME_FAIL'

export const FLAG_NAME_PAIR_ATTEMPT = 'FLAG_NAME_PAIR_ATTEMPT'
export const FLAG_NAME_PAIR_SUCCESS = 'FLAG_NAME_PAIR_SUCCESS'
export const FLAG_NAME_PAIR_FAIL = 'FLAG_NAME_PAIR_FAIL'

/*===== General Types, used as a general type =====*/
export interface Name {
    uuid: string,
    isLiked: boolean
    isFlagged: boolean
    name_normalized?: string,
    nicknames?: nameConnection
    formalNames?: nameConnection
}

export interface Edge {
    cursor: string
    isLiked: boolean
    isFlagged: boolean
    node: Name
}

export interface pageInfo {
    endCursor: string
    startCursor: string 
    hasNextPage: boolean
    hasPreviousPage: boolean
}

export interface nameConnection {
    edges?: Edge[]
    pageInfo?: pageInfo
    totalCount: number
}

export type uuid = string

export type EdgeIds = Array<{ id: string, start: boolean }>
export interface NameElement {
	current: {
        top: number
        left: number
    }
    target: {
        top: number
        left: number
    }
    isLiked: boolean
    isFlagged: boolean
    edgeIsLiked: boolean
    edgeIsFlagged: boolean
	nameString: string | null
	uuid: string
	numberOfConnections: number
	radius: number
    relation: NameNodeRelationOptions
    edgeIds: EdgeIds
}

export interface NameMemo { [uuid: string] : NameElement }
export interface EdgeMemo { [id:string] : EdgeElement }

export interface EdgeElement {
	start: {
        uuid: string
	},
	end: {
        uuid: string
	}
}

export type NameNodeRelationOptions =  "nickname" | "formalName" | "primaryName" | "unrelated"

export interface NamePair {
    nickname: string | null
    formalName: string | null
}

/*===== Action Types, defines actions for Redux =====*/
export interface GetNameAttempt {
    type: typeof GET_NAME_ATTEMPT
    currentName: string
}

export interface GetNameSuccess {
    type: typeof GET_NAME_SUCCESS
    name: Name
    edgeMemo: EdgeMemo
    nameMemo: NameMemo
}

export interface GetNameFail {
    type: typeof GET_NAME_FAIL
    err: Error
}

export interface SuggestNameAttempt {
    type: typeof SUGGEST_NAME_ATTEMPT
}

export interface SuggestNameSuccess {
    type: typeof SUGGEST_NAME_SUCCESS
}

export interface SuggestNameFail {
    type: typeof SUGGEST_NAME_FAIL
    err: Error
}


export interface MoveNodes {
    type: typeof MOVE_NODES
    nameMemo: NameMemo
}

export interface MoveOneNode {
    type: typeof MOVE_ONE_NODE
    uuid: uuid
    nameElement: NameElement
}
export interface UpdateSuggestNamePair {
    type: typeof UPDATE_SUGGEST_NAME_PAIR
    namePair: NamePair
    relationToSuggest: "nickname" | "formalName"
    explanation: string | undefined
}

export interface LikeNameAttempt {
    type: typeof LIKE_NAME_ATTEMPT
}

export interface LikeNameSuccess {
    type: typeof LIKE_NAME_SUCCESS
    name: Name
}

export interface LikeNameFail {
    type: typeof LIKE_NAME_FAIL
    err: Error
}

export interface LikeNamePairAttempt {
    type: typeof LIKE_NAME_PAIR_ATTEMPT
}

export interface LikeNamePairSuccess {
    type: typeof LIKE_NAME_PAIR_SUCCESS
    uuidToUpdate: string
}

export interface LikeNamePairFail {
    type: typeof LIKE_NAME_PAIR_FAIL
    err: Error
}

export interface FlagNameAttempt {
    type: typeof FLAG_NAME_ATTEMPT
}

export interface FlagNameSuccess {
    type: typeof FLAG_NAME_SUCCESS
    name: Name
}

export interface FlagNameFail {
    type: typeof FLAG_NAME_FAIL
    err: Error
}

export interface FlagNamePairAttempt {
    type: typeof FLAG_NAME_PAIR_ATTEMPT
}

export interface FlagNamePairSuccess {
    type: typeof FLAG_NAME_PAIR_SUCCESS
    uuidToUpdate: string
}

export interface FlagNamePairFail {
    type: typeof FLAG_NAME_PAIR_FAIL
    err: Error
}

export type NameActionsTypes = GetNameAttempt | 
GetNameSuccess | 
GetNameFail | 
MoveNodes |
MoveOneNode |
UpdateSuggestNamePair |
SuggestNameAttempt |
SuggestNameSuccess |
SuggestNameFail |
LikeNameAttempt |
LikeNameSuccess |
LikeNameFail |
LikeNamePairAttempt |
LikeNamePairSuccess |
LikeNamePairFail |
FlagNameAttempt |
FlagNameSuccess |
FlagNameFail |
FlagNamePairAttempt |
FlagNamePairSuccess |
FlagNamePairFail