import React from 'react'
import DrawableComponent from '../DrawableComponent'
import { connect } from 'react-redux'
import { AppState } from '../../reducers'
import { NameMemo, EdgeMemo } from '../../actions'
import { ViewPort } from '../../reducers/ui.reducers'


export interface Props {
	edgeId: string
	width: number
	height: number
	ref?:string
	edgeMemo: EdgeMemo
	nameMemo: NameMemo
	viewPort: ViewPort
	selectedUuid: string | undefined
}

class NameEdge extends DrawableComponent<Props> {
	
	rotatePoint(x:number, y:number, rads:number):{x:number, y:number}{
		return {
			x: Math.cos(rads)*x - Math.sin(rads)*y,
			y: Math.sin(rads)*x + Math.cos(rads)*y
		}
	}

	drawElement(ctx: CanvasRenderingContext2D, mouse: { top: number, left: number }){
		const relation = "nickname"
		const { focus, depth } = this.props.viewPort
		const startNode = this.props.nameMemo[this.props.edgeMemo[this.props.edgeId].start.uuid].current
		const endNodel = this.props.nameMemo[this.props.edgeMemo[this.props.edgeId].end.uuid].current
		const start = {
			top: (focus * startNode.top/(depth+focus)) + this.props.height/2 - this.props.viewPort.center.top,
			left: (focus * startNode.left/(depth+focus)) + this.props.width/2 - this.props.viewPort.center.left,
		}
		let endCenter = {
			top: (focus * endNodel.top/(depth+focus)) + this.props.height/2 - this.props.viewPort.center.top,
			left: (focus * endNodel.left/(depth+focus)) + this.props.width/2- this.props.viewPort.center.left,
		}

		let length = Math.sqrt(Math.pow(endCenter.top-start.top, 2) + Math.pow(endCenter.left-start.left, 2))
		const unitVector = {
			left: (endCenter.left - start.left) / length,
			top: (endCenter.top - start.top) / length,
		}

		const radius = focus * this.props.nameMemo[this.props.edgeMemo[this.props.edgeId].start.uuid].radius/(depth+focus)
		const end = {
			top: (unitVector.top * (length - radius - 5)) + start.top,
			left: (unitVector.left * (length - radius - 5)) + start.left
		}

		let colors = {
			dark: {
				nickname: '#4d94ff',
				formalName: '#00cc00'
			},
			light: {
				nickname: '#cce0ff',
				formalName: '#b3ffb3'
			}
		}
		const gradUnitLength = 60
		length = Math.sqrt(Math.pow(end.top-start.top, 2) + Math.pow(end.left-start.left, 2))
		const lengthSections = Math.ceil(length / gradUnitLength) + 1;
		let rotation = Math.acos((start.left-end.left) / length)
		if(start.top > end.top){
			rotation =  2 * Math.PI - rotation
		}

		const arrowPointsOne = this.rotatePoint(10,10,rotation)
		const arrowPointsTwo = this.rotatePoint(10,-10,rotation)
		
		const gradStart = {
			x: end.left - ((length + gradUnitLength)*(end.left - start.left))/length,
			y: end.top - ((length + gradUnitLength)*(end.top - start.top))/length
		}

		const gradStop = {
			x: start.left + ((lengthSections * gradUnitLength)*(end.left - start.left))/length,
			y: start.top + ((lengthSections * gradUnitLength)*(end.top - start.top))/length
		}

		if(false && (!isNaN(gradStart.x) || !isNaN(gradStart.y) || !isNaN(gradStop.x) || !isNaN(gradStop.y))){
			let gradient = ctx.createLinearGradient(gradStart.x, gradStart.y, gradStop.x, gradStop.y)
			
			const calculateOffset = (section: number, lengthSections: number, i: number): number => {
				const milliseconds = new Date().getMilliseconds()/1000
				return ((section/((lengthSections + 1)*3)) + (milliseconds+i)/(lengthSections+1))			
			}

			for(let i = 0; i < lengthSections  + 1; i++){
				const offset1 = calculateOffset(1, lengthSections, i)
				if( offset1 <= 1){
					gradient.addColorStop( offset1, colors.light[relation]);
				}
				const offset2 = calculateOffset(2, lengthSections, i)
				if (offset2 <= 1){
					gradient.addColorStop( offset2 , colors.dark[relation]);
				}
				const offset3 = calculateOffset(3, lengthSections, i)
				if (offset3 <=1 ){
					gradient.addColorStop( offset3 , colors.dark[relation]);
				}
				
			}
			ctx.strokeStyle = gradient//colors[this.props.relation]
		} else {
			
		}
		// ctx.fillStyle = "red";
		// ctx.fillRect(gradStart.x,gradStart.y,1,1)
		// ctx.fillRect(gradStop.x, gradStop.y,1,1)
		
		
		
		ctx.beginPath();
		ctx.lineCap = "round";
		ctx.moveTo(start.left, start.top);
		ctx.lineTo(end.left, end.top);
		ctx.lineJoin = "round";
		ctx.moveTo(end.left+arrowPointsOne.x, end.top-arrowPointsOne.y);
		ctx.lineTo(end.left, end.top);
		ctx.lineTo(end.left+arrowPointsTwo.x, end.top-arrowPointsTwo.y);
		
		ctx.lineWidth = 7;
		ctx.strokeStyle = "white"
		ctx.stroke();
		ctx.lineWidth = 5;
		ctx.strokeStyle = "grey"
		ctx.stroke();

		let color = colors.dark.nickname


		if(this.props.edgeMemo[this.props.edgeId].start.uuid === this.props.selectedUuid || this.props.edgeMemo[this.props.edgeId].end.uuid === this.props.selectedUuid){
			color = '#e6c300'
		}

		ctx.lineWidth = 3;
		ctx.strokeStyle = color
		
		ctx.stroke();
		ctx.strokeStyle = "black"
		
	}

	render(){
		return(
			<></>
		)
	}
}


const mapStateToProps = (state: AppState) => ({
	edgeMemo: state.nicknames.edgeMemo,
	nameMemo: state.names,
	width: state.uiStates.canvas.width,
	height: state.uiStates.canvas.height,
	viewPort: state.uiStates.viewPort,
	selectedUuid: state.nicknames.current.name?.uuid
});

export default connect(
	mapStateToProps,
	{ },
	null,
	{ forwardRef: true },
)(NameEdge)
