export const SEARCH_NAME_ATTEMPT = "SEARCH_NAME_ATTEMPT"
export const SEARCH_NAME_SUCCESS = "SEARCH_NAME_SUCCESS"
export const SEARCH_NAME_FAIL = "SEARCH_NAME_FAIL"


/*===== General Types, used as a general type =====*/

/*===== Action Types, defines actions for Redux =====*/

export interface SearchNameAttempt {
	type: typeof SEARCH_NAME_ATTEMPT
	query: string
}

export interface SearchNameSuccess {
	type: typeof SEARCH_NAME_SUCCESS
	names: string[]
}

export interface SearchNameFail {
    type: typeof SEARCH_NAME_FAIL
    err: Error
}

export type NameSearchActionsTypes = SearchNameAttempt |
SearchNameSuccess | 
SearchNameFail